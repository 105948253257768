import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { ManageCampaignService } from 'src/app/private/services/manage-campaign.service';
import { UsersService } from 'src/app/private/services/users.service';
import { WhatsappTemplateService } from 'src/app/private/services/whatsapp-tempate.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-broadcast-campaign',
  templateUrl: './broadcast-campaign.component.html',
  styleUrls: ['./broadcast-campaign.component.scss']
})
export class BroadcastCampaignComponent {

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  totalRows = 0;
  currentPage = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [10, 25, 100];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  btnLoading = false;
  initialLoad = true;
  reloading = false;
  emailTemplates: any[] = [];
  whatsappTemplates: any[] = [];
  contactGroups: any[] = [];

  isFetchingTemplates = false;
  whatsappPreviewTemplate: any;
  processedData: any;

  active = "whatsapp"

  campaign = {
    "id": "",
    "campaign_name": "",
    "contact_group_id": "",
    "campaign_source": "",
    "campaign_date": "",
    "template_id": "",
    "is_schedule": false,
    "status": "pending",
    "hospital_id": "",
    "center_id": "",
  }

  displayedColumns: string[] = ["campaign_name", "campaign_group", "campaign_date",

    "is_schedule",
    "schedule_date",
    "schedule_time",
    "status", "action"];

  
    defaultFilter = [
      "campaign_name",
      "status",
    ]


  constructor(
    private toast: ToastrService,
    private api: ManageCampaignService,
    private modalService: NgbModal,
    private localStorage: LocalStorageService,
    private sanitizer: DomSanitizer
  ) {

  }

  ngAfterViewInit() {
    this.loadData();
    this.fetchContactGroups(); // Fetch contact groups on initialization
    this.fetchEmailTemplates();
    this.fetchWhatsAppTemplates();
  }

  fetchEmailTemplates() {

    const data = {
      "client_id": this.localStorage.getClientId()
    }

    this.api.getAllEmailTemplates(data).subscribe((res: any) => {
      this.emailTemplates = res;
    }
    );
  }

  fetchWhatsAppTemplates() {

    const param = {
      "client_id": this.localStorage.getClientId(),
      "status": "APPROVED"
    }


    this.api.getAllWhatsappTemplatesGupshup(param).subscribe((res: any) => {

      this.whatsappTemplates = res;
    }
    );
  }

  fetchContactGroups() {
    const payload = {
      "where": {
        "client_id": this.localStorage.getClientId()
      },
      "order": {
        "col": "\"contact_group\".\"updatedAt\"",
        "dir": "DESC"
      }
    }
    this.api.getAllContactGroups(payload).subscribe((res: any) => {
      this.contactGroups = res;
    }
    );
  }


  loadData(type?: string) {


    try {
      merge(
        this.dateQuery.valueChanges,
        this.searchQuery.valueChanges,
        this.sort.sortChange,
        this.paginator.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            this.reloading = true;

            const payload:any = {
              "where": {
                "client_id": this.localStorage.getClientId(),
                "campaign_type": this.active,
              },
              "page": this.paginator.pageIndex * this.paginator.pageSize,
              "limit": this.paginator.pageSize,
              "order": {
                "col": "\"campaign\".\"updatedAt\"",
                "dir": "DESC"
              }
            }

            if (this.searchQuery.value) {

              for (let [key, value] of Object.entries(this.searchQuery.value)) {

                if (this.defaultFilter.includes(key)) {
                  key = (key == 'assign_to') ? 'assign_to' : key
                  const obj = {
                    ...payload.where,
                    [key]: value,
                  };
                  payload.where = obj;
                }
              }

            }


            return this.api
              .getAllManangeCampaign(payload)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            this.reloading = false;
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            return response.rows;
          })
        )
        .subscribe({
          next: (data) => {
            this.initialLoad = false;
            this.btnLoading = false;
            this.dataSource = new MatTableDataSource<any>(data);

            this.modalService.dismissAll();


          },
          error: (e) => {
            this.btnLoading = false;
            this.initialLoad = false;
            this.reloading = false;
            console.error(e);
          },
          complete: () => {
            this.reloading = false;
          },
        });
    } catch (e) {
      this.reloading = false;
      console.error(e);
    }
  }

  reload(type?: string) {


    try {
      merge(
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            this.reloading = true;
            const payload = {
              "where": {
                "client_id": this.localStorage.getClientId(),
                "campaign_type": this.active,
              },
              "page": this.paginator.pageIndex * this.paginator.pageSize,
              "limit": this.paginator.pageSize,
              "order": {
                "col": "\"campaign\".\"updatedAt\"",
                "dir": "DESC"
              }
            }

            if (this.searchQuery.value) {

              for (let [key, value] of Object.entries(this.searchQuery.value)) {

                if (this.defaultFilter.includes(key)) {
                  key = (key == 'assign_to') ? 'assign_to' : key
                  const obj = {
                    ...payload.where,
                    [key]: value,
                  };
                  payload.where = obj;
                }
              }

            }

            return this.api
              .getAllManangeCampaign(payload)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            this.reloading = false;
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            return response.rows;
          })
        )
        .subscribe({
          next: (data) => {
            this.initialLoad = false;
            this.btnLoading = false;
            this.dataSource = new MatTableDataSource<any>(data);

            this.modalService.dismissAll();


          },
          error: (e) => {
            this.btnLoading = false;
            this.initialLoad = false;
            this.reloading = false;
            console.error(e);
          },
          complete: () => {
          },
        });
    } catch (e) {
      this.reloading = false;
      console.error(e);
    }
  }

  handleUpdateData(type?: string) {
    try {
      this.btnLoading = true;
      let params = {
        "where": {
          "client_id": this.localStorage.getClientId(),
          "campaign_type": this.active,
        },
        "page": this.paginator.pageIndex * this.paginator.pageSize,
        "limit": this.paginator.pageSize,
        "order": {
          "col": "\"campaign\".\"updatedAt\"",
          "dir": "DESC"
        }
      }

      if (this.searchQuery.value) {

        for (let [key, value] of Object.entries(this.searchQuery.value)) {

          if (this.defaultFilter.includes(key)) {
            key = (key == 'assign_to') ? 'assign_to' : key
            const obj = {
              ...params.where,
              [key]: value,
            };
            params.where = obj;
          }
        }

      }

      this.api.getAllManangeCampaign(params).subscribe(
        {
          next: (response) => {
            this.initialLoad = false;
            this.dataSource = new MatTableDataSource<any>(response.rows);

            if (type == 'create') {
              this.toast.success('Campaign created successfully');
            } else if (type == 'update') {
              this.toast.success('Campaign updated successfully');
            } else if (type == 'delete') {
              this.toast.success('Campaign deleted successfully');
            }

            this.btnLoading = false;
            this.modalService.dismissAll();



          },
          error: (e) => {
            this.initialLoad = false;
            this.btnLoading = false;
            console.error(e);
          },
          complete: () => {
          },
        }
      )


    } catch (e) {
      console.error(e);
    }
  }

  searchFilter(query: any) {
    console.log('Search Query:', query);
    this.searchQuery.setValue(query);
  }


  open(content: any) {
    this.modalService
      .open(content, { size: 'lg', scrollable: true, centered: true })
  }

  changeTab(type: any) {
    this.active = type;
    this.initialLoad = true;
    this.dataSource = new MatTableDataSource<any>([]);
    this.loadData()
    this.campaign = {
      "id": "",
      "campaign_name": "",
      "contact_group_id": "",
      "campaign_source": "",
      "campaign_date": "",
      "template_id": "",
      "is_schedule": false,
      "status": "pending",
      "hospital_id": "",
      "center_id": "",
    }
  }

  edit(content: any, event: any) {
    this.modalService
      .open(content, { size: 'lg', scrollable: true, centered: true })
    this.campaign = event
    // this.id = event.id;
  }

  delete(content: any, event: any) {

  }

  handleGetWhatsappTemplateById(id: any) {

    this.isFetchingTemplates = true;
    const payload = {
      "client_id": this.localStorage.getClientId(),
      "template_id": id
    }
    this.api.getTemplateById(payload).subscribe(
      (res: any) => {
        this.whatsappPreviewTemplate = res;
        console.log("whatsapptemplate",this.whatsappPreviewTemplate)
        this.isFetchingTemplates = false
      },
      (error: any) => {
        this.isFetchingTemplates = false
        console.error('Error creating campaign', error);
      }
    );
  }

  handleGetEmailTemplateById(id: any) {
    this.isFetchingTemplates = true;
    const payload = {
      "client_id": this.localStorage.getClientId(),
      "template_id": id
    }
    this.api.getEmailTemplateById(payload).subscribe(
      (res: any) => {
        this.processedData =  this.sanitizer.bypassSecurityTrustHtml(res.template_body); 
        this.isFetchingTemplates = false
      },
      (error: any) => {
        this.isFetchingTemplates = false
        console.error('Error creating campaign', error);

      }

    );
  }

  run(content: any, event: any) {
    this.campaign = event;

    if(this.active === 'whatsapp') {
      this.handleGetWhatsappTemplateById(event.template_id);
    }else if(this.active === 'email') {
      this.handleGetEmailTemplateById(event.template_id);
    }
    
    this.modalService
      .open(content, { size: 'md', scrollable: true, centered: true })

  }

  runCampaign() {

    this.btnLoading = true;

    const payload = {
      broadcastType: this.active,
      client_id: this.localStorage.getClientId(),
      contact_group_id: this.campaign.contact_group_id,
      template_id: this.campaign.template_id,
      subject: this.active === 'email' ? this.campaign?.campaign_name : null,
      campaign_id: this.campaign?.id

    }

    this.api.runCampaign(payload).subscribe(
      (res: any) => {
        this.btnLoading = false;
        this.modalService.dismissAll();
        this.reload();
      },
      (error: any) => {
        console.error('Error creating campaign', error);
        this.btnLoading = false;
      }
    );
  }

  deleteCampaign() {
    this.btnLoading = true;
    console.log(this.campaign)
    const payload = {
      id: this.campaign.id
    }
    this.api.deleteCampaign(payload).subscribe(
      (res: any) => {
        this.handleUpdateData('delete');
      },
      (error: any) => {
        console.error('Error creating campaign', error);
        this.btnLoading = false;
      }
    );
  }


}


function observableOf(arg0: null): any {
  console.log(arg0);
  throw new Error('Function not implemented.');
}