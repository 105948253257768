import { Component, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { of } from 'rxjs';
import { MailMiddleSectionComponent } from 'src/app/private/modules/components/sales-mail-hub/mail-middle-section/mail-middle-section.component';
import { MailRightSectionComponent } from 'src/app/private/modules/components/sales-mail-hub/mail-right-section/mail-right-section.component';
import { EmailHubService } from 'src/app/private/services/email-hub.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-sales-mail-hub',
  templateUrl: './sales-mail-hub.component.html',
  styleUrls: ['./sales-mail-hub.component.scss']
})
export class SalesMailHubComponent {
  @ViewChild(MailMiddleSectionComponent) childComponent!: MailMiddleSectionComponent;
  @ViewChild(MailRightSectionComponent) rightComponent!: MailRightSectionComponent;
  selectedRow: any;
  activeAction: string = 'inbox';
  sanitizedContent: any;
  isComposeOpen = false;
  loadingEmailData = false;
  isDraft = false;
  updateDraft = false;
  fetchingDraftData = false;
  currentCreatedDraftId: any;


  constructor(
    private sanitizer: DomSanitizer,
    private api: EmailHubService,
    private localStorage: LocalStorageService
  ) { }

  ngOnDestroy(): void {
    // Add cleanup or event logging logic here
    console.log('SalesMailHubComponent is being destroyed');

    if (this.isDraft) {
      console.log('Saving draft before exit...');
      // Call a service or method to save the draft
      this.saveDraft();
    }
  }

  saveDraft() {
    // Logic to save the draft email
    console.log('Draft saved.');


    let attachments: any[] = [];
    this.rightComponent.files.forEach((file) => {
      attachments.push({
        "@odata.type": "#microsoft.graph.fileAttachment",
        "name": file.file.name,
        "contentType": file.file.type,
        "contentBytes": file.preview?.split(',')[1]
      });
    });

    this.isDraft = false;

    const param = {
      subject: this.rightComponent.subject,
      contentType: 'HTML',
      bodyContent: this.rightComponent.editorData,
      recipientEmails: this.rightComponent.emails?.length > 0 ? this.rightComponent.emails : [this.rightComponent.emailInput],
      fromEmail: this.localStorage.getEmail(),
      ccRecipients: this.rightComponent.ccEmails?.length > 0 ? this.rightComponent.ccEmails : this.rightComponent.ccInput ? [this.rightComponent.ccInput] : [],
      attachments: attachments,
      client_id: this.localStorage.getClientId()
    }

    this.rightComponent.clearData();

    console.log('Draft email param:', param);
    this.api.createOutlookDraftEmail(param).subscribe({
      next: (res: any) => {
        this.currentCreatedDraftId = res.id;
        console.log('Draft saved successfully:', res);
        if (this.activeAction === 'draft') {
          this.handleUpdateData2();
        }
      },
      error: (err: any) => {
        console.error('Error saving draft:', err);
      }
    });
  }

  handleUpdateDraft() {
    // Logic to update the draft email
    console.log('Draft updated.');

    const param = {
      subject: this.rightComponent.subject,
      contentType: 'HTML',
      bodyContent: this.rightComponent.editorData,
      recipientEmails: this.rightComponent.emails?.length > 0 ? this.rightComponent.emails : [this.rightComponent.emailInput],
      fromEmail: this.localStorage.getEmail(),
      ccRecipients: this.rightComponent.ccEmails?.length > 0 ? this.rightComponent.ccEmails : this.rightComponent.ccInput ? [this.rightComponent.ccInput] : [],
      // attachments: this.rightComponent.files,
      client_id: this.localStorage.getClientId(),
      messageId: this.selectedRow?.id,
      draft_message_id: this.selectedRow?.id ? this.selectedRow?.id : this.currentCreatedDraftId
    }

    this.rightComponent.clearData();

    if (this.rightComponent.files.length > 0) {

    }


    console.log('Draft email param:', param);
    this.api.updateOutlookDraftEmail(param).subscribe({
      next: (res: any) => {
        console.log('Draft updated successfully:', res);
        this.updateDraft = false;
        this.handleUpdateData2();
      },
      error: (err: any) => {
        console.error('Error updating draft:', err);
      }
    });
  }

  handleUpdateDraft2(param: any) {
    // Logic to update the draft email
    console.log('Draft updated.');



    this.rightComponent.clearData();

    // if(this.rightComponent.files.length > 0) {

    // }


    console.log('Draft email param:', param);
    this.api.updateOutlookDraftEmail(param).subscribe({
      next: (res: any) => {
        console.log('Draft updated successfully:', res);
        this.updateDraft = false;
        this.handleUpdateData2();
      },
      error: (err: any) => {
        console.error('Error updating draft:', err);
      }
    });
  }

  handleUpdateDraft3() {
    // Logic to update the draft email
    console.log('Draft updated.');

    const param = {
      subject: this.rightComponent.subject,
      contentType: 'HTML',
      bodyContent: this.rightComponent.editorData,
      recipientEmails: this.rightComponent.emails?.length > 0 ? this.rightComponent.emails : [this.rightComponent.emailInput],
      fromEmail: this.localStorage.getEmail(),
      ccRecipients: this.rightComponent.ccEmails?.length > 0 ? this.rightComponent.ccEmails : this.rightComponent.ccInput ? [this.rightComponent.ccInput] : [],
      // attachments: this.rightComponent.files,
      client_id: this.localStorage.getClientId(),
      draft_message_id: this.currentCreatedDraftId
    }

    this.rightComponent.clearData();


    console.log('Draft email param:', param);
    this.api.updateOutlookDraftEmail(param).subscribe({
      next: (res: any) => {
        console.log('Draft updated successfully:', res);
        this.updateDraft = false;
        this.handleUpdateData2();
      },
      error: (err: any) => {
        console.error('Error updating draft:', err);
      }
    });
  }


  onActionChange(action: string) {

    if (this.loadingEmailData) {
      return;
    }

    this.activeAction = action;
  }

  handleUpdateData() {
    console.log('Refetching data...');
    this.childComponent.refetch();
  }

  handleUpdateData2() {
    console.log('Refetching data...');
    this.childComponent.refetch();
  }




  handleSelectRow(row: any) {
    this.isComposeOpen = false;

    if (this.isDraft) {
      this.handleUpdateDraft();

      this.isDraft = false;
    }

    if (this.updateDraft) {
      this.handleUpdateDraft();
      this.updateDraft = false;
    }

    console.log('Selected row:', row);
    this.selectedRow = row;

    if (this.activeAction === 'drafts') {
      this.fetchingDraftData = true;
    } else {
      this.fetchingDraftData = false;
    }



    try {
      const payload = {
        client_id: this.localStorage.getClientId(),
        user_email: this.localStorage.getEmail(),
        messageId: this.selectedRow?.id
      };

      this.loadingEmailData = true;

      const payload2 = {
        fromEmail: this.localStorage.getEmail(),
        client_id: this.localStorage.getClientId(),
        message_id: this.selectedRow?.id
      }

      this.api.getOutlookEmailAttachments(payload2).subscribe({
        next: (res: any) => {
          console.log('Email attachments:', res);
          this.rightComponent.attachments = res?.value;
          console.log('Email attachments set successfully.');
        },
        error: (err: any) => {
          console.error('Error fetching email attachments:', err);
        }
      });

      // Fetch email with attachment
      this.api.getOutlookEmailWithAttachment(payload).subscribe({
        next: (res: any) => {
          // Sanitize and trust the email content
          console.log('Email content:', res);
          this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(res);
          console.log('Email content sanitized and set successfully.');
          this.loadingEmailData = false

          if (this.activeAction === 'drafts') {
            this.isComposeOpen = true;
            this.isDraft = false;
            this.updateDraft = true;
            this.rightComponent.fillData(this.selectedRow, res);

            return;
          }

        },
        error: (err: any) => {
          // Log and handle API errors
          this.loadingEmailData = false;
          console.error('Error fetching email with attachment:', err);
        }
      });
    } catch (e) {
      console.error('Error in handleSelectRow:', e);
    }
  }

  handleCompose() {
    this.isComposeOpen = true;
    this.isDraft = true;
    this.saveDraft();
  }

  handleComposeClose() {

    if (this.currentCreatedDraftId) {
      if (this.rightComponent.files.length > 0) {
        this.handleUpdateDraftandAddAttachment();
      } else if (this.isDraft) {
        this.handleUpdateDraft3();
      }else{
        this.handleUpdateDraft();
      }
      this.currentCreatedDraftId = null;
    }

    // if(this.isDraft) {
    //   this.saveDraft();
    // }

    if (this.updateDraft) {
      if (this.rightComponent.files.length > 0) {
        this.handleUpdateDraftandAddAttachment();
      }else{
        this.handleUpdateDraft();

      }
    }


    this.isComposeOpen = false;
    this.isDraft = false;
    this.updateDraft = false;
    this.selectedRow = null;
    this.fetchingDraftData = false;
  }

  handleSaveImageandUpdateDraft() {
    this.isComposeOpen = false;
    this.isDraft = false;
    this.updateDraft = false;
    this.selectedRow = null;
    this.fetchingDraftData = false;

    const attachments = this.rightComponent?.files.map((file) => ({
      "@odata.type": "#microsoft.graph.fileAttachment",
      "name": file.file.name,
      "contentType": file.file.type,
      "contentBytes": file.preview?.split(',')[1]
    }));

    const attachmentPayload = {
      fromEmail: this.localStorage.getEmail(),
      draft_id: this.rightComponent.replyId,
      client_id: this.localStorage.getClientId(),
      attachments: attachments
    };

    const param = {
      subject: this.rightComponent.subject,
      contentType: 'HTML',
      bodyContent: this.rightComponent.editorData,
      recipientEmails: this.rightComponent.emails?.length > 0 ? this.rightComponent.emails : [this.rightComponent.emailInput],
      fromEmail: this.localStorage.getEmail(),
      ccRecipients: this.rightComponent.ccEmails?.length > 0 ? this.rightComponent.ccEmails : this.rightComponent.ccInput ? [this.rightComponent.ccInput] : [],
      // attachments: this.rightComponent.files,
      client_id: this.localStorage.getClientId(),
      messageId: this.selectedRow?.id,
      draft_message_id: this.rightComponent.replyId
    }


    // Call addAttachmentInOutlookReply first, then handleUpdateDraft
    this.api.addAttachmentInOutlookReply(attachmentPayload).subscribe({
      next: () => {
        this.handleUpdateDraft2(param)
      },
      error: (error) => {
        this.rightComponent.handleError(error, 'Error adding attachments');
      }
    });
  }

  handleMakeIsDraftFalse() {
    this.isDraft = false;
    this.updateDraft = false;
    this.currentCreatedDraftId = null;
  }

  handleMakeIsDraftTrue() {
    this.isDraft = true;
  }


  handleUpdateDraftandAddAttachment() {
    console.log('Updating draft and adding attachment...');

    const attachments = this.rightComponent?.files.map((file) => ({
      "@odata.type": "#microsoft.graph.fileAttachment",
      "name": file.file.name,
      "contentType": file.file.type,
      "contentBytes": file.preview?.split(',')[1]
    }));

    const attachmentPayload = {
      fromEmail: this.localStorage.getEmail(),
      draft_id: this.currentCreatedDraftId ? this.currentCreatedDraftId : this.selectedRow?.id,
      client_id: this.localStorage.getClientId(),
      attachments: attachments
    };

    const param = {
      subject: this.rightComponent.subject,
      contentType: 'HTML',
      bodyContent: this.rightComponent.editorData,
      recipientEmails: this.rightComponent.emails?.length > 0 ? this.rightComponent.emails : [this.rightComponent.emailInput],
      fromEmail: this.localStorage.getEmail(),
      ccRecipients: this.rightComponent.ccEmails?.length > 0 ? this.rightComponent.ccEmails : this.rightComponent.ccInput ? [this.rightComponent.ccInput] : [],
      // attachments: this.rightComponent.files,
      client_id: this.localStorage.getClientId(),
      messageId: this.selectedRow?.id,
      draft_message_id: this.currentCreatedDraftId ? this.currentCreatedDraftId : this.selectedRow?.id
    }

    // Call addAttachmentInOutlookDraft first, then handleUpdateDraft
    this.api.addAttachmentInOutlookReply(attachmentPayload).subscribe({
      next: () => {
        
      },
      error: (error) => {
        this.rightComponent.handleError(error, 'Error adding attachments');
      }
    });

    this.handleUpdateDraft2(param);
  }
}
