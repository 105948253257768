import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PipeLineService {

  constructor(private http: HttpClient) { }


  getAllPipeLine(data: any): Observable<any> {
    return this.http.post(environment.getAllPipeLine, data);
  }

  createPipeLine(data: any): Observable<any> {
    return this.http.post(environment.createPipeLine, data);
  }

  updatePipeLine(data: any): Observable<any> {
    return this.http.post(environment.updatePipeLine, data);
  }

  deletePipeLine(data: any): Observable<any> {
    return this.http.post(environment.deletePipeLine, data);
  }

}
