import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EmailHubService {

  constructor(private http: HttpClient) { }

  getOutlookInboxEmail(data:any): Observable<any> {
    return this.http.post(environment.outlookInboxEmail,data);
  }

  getOutlookSentEmail(data:any): Observable<any> {
    return this.http.post(environment.outlookSentEmail,data);
  }

  getOutlookDraftEmail(data:any): Observable<any> {
    return this.http.post(environment.outlookDraftEmail,data);
  }

  getOutlookTrashEmail(data:any): Observable<any> {
    return this.http.post(environment.outlookJunkEmail,data);
  }

 

  deleteOutlookEmail(data:any): Observable<any> {
    return this.http.post(environment.deleteOutlookEmail,data);
  }

  deleteOutlookEmailPermanently(data:any): Observable<any> {
    return this.http.post(environment.deleteOutlookEmailPermanently,data);
  }

  getOutlookDeletedEmail(data:any): Observable<any> {
    return this.http.post(environment.outlookDeletedEmail,data);
  }

  sendEmailUsingOutlook(data:any): Observable<any> {
    return this.http.post(environment.sendEmailUsingOutlook,data);
  }

  sendOutlookDraftEmail(data:any): Observable<any> {
    return this.http.post(environment.sendOutlookDraftEmail,data);
  }

  getOutlookEmailWithAttachment(data:any): Observable<any> {
    return this.http.post(environment.getOutlookEmailWithAttachment,data);
  }

  createOutlookDraftEmail(data:any): Observable<any> {
    return this.http.post(environment.createOutlookDraftEmail,data);
  }

  updateOutlookDraftEmail(data:any): Observable<any> {
    return this.http.post(environment.updateOutlookDraftEmail,data);
  }

  createOutlookReply(data:any): Observable<any> {
    return this.http.post(environment.createOutlookReply,data);
  }

  createOutlookReplyAll(data:any): Observable<any> {
    return this.http.post(environment.createOutlookReplyAll,data);
  }

  sendOutlookReply(data:any): Observable<any> {
    return this.http.post(environment.sendOutlookReply,data);
  }

  forwardOutlookEmail(data:any): Observable<any> {
    return this.http.post(environment.forwardOutlookEmail,data);
  }

  getOutlookEmailAttachments(data:any): Observable<any> {
    return this.http.post(environment.getOutlookEmailAttachments,data);
  }

  addAttachmentInOutlookReply(data:any): Observable<any> {
    return this.http.post(environment.addAttachmentInOutlookReply,data);
  }

  deleteAttachmentInOutlook(data:any): Observable<any> {
    return this.http.post(environment.deleteAttachmentInOutlook,data);
  }
  
}
