import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AutoCompleteContactSearchComponent } from 'src/app/private/modules/components/auto-complete-contact-search/auto-complete-contact-search.component';
import { AutoCompleteSearchComponent } from 'src/app/private/modules/components/auto-complete-search/auto-complete-search.component';
import { DealService } from 'src/app/private/services/deals.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';


@Component({
  selector: 'app-create-deals',
  templateUrl: './create-deals.component.html',
  styleUrls: ['./create-deals.component.scss']
})
export class CreateDealsComponent implements OnInit {
  @Input() users: any;
  @ViewChild(AutoCompleteSearchComponent) childComponent!: AutoCompleteSearchComponent;
  @ViewChild(AutoCompleteContactSearchComponent) childComponent1!: AutoCompleteContactSearchComponent
  currency : any;
  @Input() pipelineData: any;
  @Input() pipelines: any;

  account: any = null;
  account2: any = null;
  selectedName: any = null;
  

  deal: any = {
    deal_name: '',
    deal_stage: '',
    amount: null,
    deal_type_id: null,
    deal_priority_id: null,
    pipeline_id: null,
    user_id: null,
    contact_id: null,
    description: '',
    lead_source: '',
    next_step: '',
    client_id: null,
    account_id: null,
    probability_id: null,
    close_date: null
  };
  btnLoading = false;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() reload: EventEmitter<any> = new EventEmitter();
  @Input() formAction: string = 'Add';
  @Input() editData: any = null;

  dealStages: any = [
   
  ];

  leadSources: string[] = [
    "Channel Partner",
    "Conference",
    "Field Visit",
    "Google",
    "Instagram",
    "Lead Gen Tools",
    "Live Session",
    "Personal",
    "Referral",
    "LinkedIn",
    "Website",
    "WhatsApp",
    "Tina",
    "Intern",
    "Other"
  ];


  dealTypes: Array<string> = [];
  priorities: Array<string> = [];
  // pipelines: Array<string> = [];
  // users: Array<{ id: number, name: string }> = [];
  contacts: Array<{ id: number, name: string }> = [];
  clients: Array<{ id: number, name: string }> = [];
  accounts: Array<{ id: number, name: string }> = [];

  constructor(
    private toast: ToastrService,
    private api: DealService,
    private localStorage: LocalStorageService
  ) { }


  ngOnInit(): void {
    this.initializeDropdowns();
   
    if (this.formAction === 'Update') {
      this.deal = {
        deal_name: this.editData.deal_name,
        deal_stage: this.editData.deal_stage,
        amount: this.editData.amount,
        deal_type_id: this.editData.deal_type,
        deal_priority_id: this.editData.deal_priority,
        pipeline_id: this.editData.pipeline,
        user_id: this.editData.deal_owner,
        contact_id: this.editData.contact_id,
        description: this.editData.description,
        lead_source: this.editData.lead_source,
        next_step: this.editData.next_step,
        client_id: this.editData.client_id,
        account_id: this.editData.account_id,
        probability_id: this.editData.probability,
        close_date: this.editData.close_date
      };
      this.account = {
        account_name: this.editData?.account?.account_name,
        account_id: this.editData.account_id
      };
      this.account2 = {
        account_name: this.editData?.contact?.first_name + ' ' + this.editData?.contact?.last_name,
        account_id: this.editData.contact_id
      };

      this.dealStages = this.pipelineData.filter((item: any) => item.pipeline_name === this.editData.pipeline)[0].stages;
    }

  }

  ngAfterViewInit() {
    this.currency = this.localStorage.getClientCurrency();
    console.log('currency', this.currency);
  }

  initializeDropdowns(): void {
    // Replace with API calls to fetch these values dynamically
    this.dealTypes = [
      "New Business",
      "Existing Business",
    ];
    this.priorities = [
      "High",
      "Medium",
      "Low"
    ];
   

  }

  getDealStages(e: any): void {
    const pipelineId = e.target.value;
    console.log('pipelineId', e?.target?.value);
    console.log('pipelineData', this.pipelineData);

    let stages =  this.pipelineData.filter((item: any) => item.pipeline_name === pipelineId);
    console.log('stages', stages);
    if (stages.length > 0) {
      this.dealStages = stages[0].stages;
    }
  }

  changePriorityValue(e: any): void {
    console.log('priority', e.target.value);

    const probabilityValue = this.dealStages.filter((item: any) => item.stage_name === e.target.value);

    console.log('probabilityValue', probabilityValue);

    if (probabilityValue.length > 0) {
      this.deal.probability_id = probabilityValue[0].probability;
    }
  }

  saveDeal(isValid: any): void {
    if (this.childComponent.selectedId == null) {
      this.toast.error('Please select all the required fields');
      return;
    }
    if (isValid) {

      const parmas: any = {
        deal_name: this.deal.deal_name,
        deal_stage: this.deal.deal_stage,
        amount: this.deal.amount,
        deal_type: this.deal.deal_type_id,
        deal_priority: this.deal.deal_priority_id,
        pipeline: this.deal.pipeline_id,
        deal_owner: this.deal.user_id,
        contact_id: this.childComponent1.selectedId,
        description: this.deal.description,
        lead_source: this.deal.lead_source,
        next_step: this.deal.next_step,
        client_id: this.localStorage.getClientId(),
        account_id: this.childComponent.selectedId,
        probability: this.deal.probability_id,
        close_date: this.deal.close_date
      };

      this.btnLoading = true;

      if (this.formAction === 'Add') {
        this.api.createDeal(parmas).subscribe(
          (response: any) => {
            console.log('API response:', response);
            this.btnLoading = false;
            this.toast.success('Deal created successfully');
            this.closeModal();
            this.reload.emit();
          },
          (error: any) => {
            console.error('API error:', error);
            this.btnLoading = false;
            this.toast.error('Failed to create deal');
          }
        );
      } else {
        // Update deal
        parmas['deal_id'] = this.editData.deal_id;
        this.api.updateDeal(parmas).subscribe(
          (response: any) => {
            console.log('API response:', response);
            this.btnLoading = false;
            this.toast.success('Deal updated successfully');
            this.closeModal();
            this.reload.emit();
          },
          (error: any) => {
            console.error('API error:', error);
            this.btnLoading = false;
            this.toast.error('Failed to update deal');
          }
        );
      }
    } else {
      console.error('Form is invalid');
    }
  }

  closeModal(): void {
    this.close.emit();
  }
}