import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Injectable({
  providedIn: 'root'
})
export class WooCommerceService {
  // private baseUrl = 'https://store.rkeon.com/wp-json/wc/v3'; // Replace with your store URL
  // private consumerKey = 'ck_b09c1aef798e5d1cb44cbf091185cee75d4f6372'; // Replace with your Consumer Key
  // private consumerSecret = 'cs_1db54b97f47d4478fe98330e76a7efa5ea0124a1'; // Replace with your Consumer Secret

  private baseUrl = ''; // Replace with your store URL
  // private consumerKey = 'ck_79811291f28dc0eb75021ff3ad3fe2733fe7de22'; // Replace with your Consumer Key
  // private consumerSecret = 'cs_ec65e11592632e8d040a1549f2504a8ca823cffc'; // Replace with your Consumer Secret
  private PDF_URL =""
  private PDF_INVOICE_ACCESS_KEY = ""
   credentials:any = {}
  
  constructor(private http: HttpClient,private localStorage:LocalStorageService) {
   this.credentials =  JSON.parse(this.localStorage.getWoocomerceCred());
   this.baseUrl = this.credentials.base_url
   this.PDF_URL = this.credentials.pdf_url
   this.PDF_INVOICE_ACCESS_KEY = this.credentials.PDF_INVOICE_ACCESS_KEY
  }

  private getAuthParams(): HttpParams {
 
    // console.log("credentials",this.credentials.consumer_key)
    return new HttpParams()
      .set('consumer_key', this.credentials.consumer_key)
      .set('consumer_secret', this.credentials.consumer_secret);
  }

  //category API

  getCategories(customParams: any): Observable<any> {
    let params = this.getAuthParams();
    // Add dynamic parameters to the request
    Object.keys(customParams).forEach((key) => {
      params = params.set(key, customParams[key]);
    });
    return this.http.get(`${this.baseUrl}/products/categories`, { params, observe: 'response' });
  }


   //Tags API

   getTags(customParams: any): Observable<any> {
    let params = this.getAuthParams();
    // Add dynamic parameters to the request
    Object.keys(customParams).forEach((key) => {
      params = params.set(key, customParams[key]);
    });
    return this.http.get(`${this.baseUrl}/products/tags`, { params, observe: 'response' });
  }

  // Products API
  getProducts(customParams: any): Observable<any> {
    let params = this.getAuthParams();

    // Add dynamic parameters to the request
    Object.keys(customParams).forEach((key) => {
      params = params.set(key, customParams[key]);
    });

    return this.http.get(`${this.baseUrl}/products`, { params, observe: 'response' });
  }


  getProductById(productId: number): Observable<any> {
    const params = this.getAuthParams();
    return this.http.get(`${this.baseUrl}/products/${productId}`, { params });
  }
  updateProductById(productId: number,updatedData:any): Observable<any> {
    const params = this.getAuthParams();
    return this.http.put(`${this.baseUrl}/products/${productId}`,updatedData, { params });
  }

  createOrder(orderData: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = this.getAuthParams();
    return this.http.post(`${this.baseUrl}/orders`, orderData, { headers, params });
  }
  // Add a new product
   addProduct(productData: any): Observable<any> {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = this.getAuthParams();
    return this.http.post(`${this.baseUrl}/products`, productData, { headers, params });
  }



    // get Order
    getOrders(customParams:any): Observable<any> {
      let params = this.getAuthParams();
      // Add dynamic parameters to the request
      Object.keys(customParams).forEach((key) => {
        params = params.set(key, customParams[key]);
      });

    return this.http.get(`${this.baseUrl}/orders`, { params, observe: 'response' });
    }
  
    updateOrders(order_id:any,customParams:any): Observable<any> {
      const params = this.getAuthParams();
      return this.http.put(`${this.baseUrl}/orders/${order_id}`,customParams, { params });
    }


  //product images

   // Upload an Image to WooCommerce Media Library
   uploadImage(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    const url = `${this.baseUrl}/media`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = this.getAuthParams();

    return this.http.post(url, formData, { headers, params });
  }

  pdfInvoice(order_id:any){
    return this.PDF_URL+"/wp-admin/admin-ajax.php?action=generate_wpo_wcpdf&document_type=invoice&order_ids="+order_id+"&access_key="+this.PDF_INVOICE_ACCESS_KEY
  }
}