import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-content-info',
  templateUrl: './content-info.component.html',
  styleUrls: ['./content-info.component.scss']
})
export class ContentInfoComponent {
[x: string]: any;
  
  @Input() element: any;
  @Input() infoArray: any;
  @Input() current_id_hover = "";
  @Input() id = "";
  @Input() title: any;
  @Output() hoverChange = new EventEmitter<string>();
  hoveredRow: any;
  topPosition = 0;
  leftPosition = 0;
  isOpening = false;
  constructor() { }

  ngAfterViewInit() {
    
  }

  hideInfo()
  {
    this.hoverChange.emit('');
  }

  showInfo(row:any,event:any)
  {
    this.isOpening = true;
    this.current_id_hover = "";
    this.topPosition = 0;
    this.leftPosition = 0;
    this.hoveredRow = row;
    console.log("getPosition",event)
    this.topPosition =  event.layerY;
    this.leftPosition = event.layerX;
   
    this.hoverChange.emit(this.id);

    setTimeout(() => {
      this.isOpening = false;
    }, 500); 
   
  }
  onMouseMove(row:any,event: MouseEvent): void {
    this.hoverChange.emit(this.id);
  }

  hideInfoClickOutside(){
    if(this.current_id_hover === this.id && !this.isOpening){
      this.hoverChange.emit('');
      this.isOpening = false;
    }else{
      setTimeout(() => {
        this.isOpening = false;
      }, 100); 
    }
  }

  formatShippingAddress(address: any): string {
    if (
      !address || // Null, undefined, or empty
      Object.keys(address).length === 0 // Empty object
    ) {
      return 'N/A';
    }
  
    const parts = [
      address.street || '',
      address.city || '',
      address.state || '',
      address.postal_code || '',
      address.country || '',
    ];
  
    // Filter out empty values and join with a space
    return parts.filter((part) => part.trim() !== '').join(' ');
  }
  

}
