import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { CompanyService } from 'src/app/private/services/company.service';
import { DealService } from 'src/app/private/services/deals.service';
import { PipeLineService } from 'src/app/private/services/pipeline.service';
import { UsersService } from 'src/app/private/services/users.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss']
})
export class DealsComponent {

  searchQuery = new FormControl();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;

  dateQuery = new FormControl();
  center_id: any;
  totalRows = 0;
  temp: any = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  formAction = 'Add';
  currentPage = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [10, 25, 100];
  btnLoading: boolean = false;
  reloading: boolean = false;
  initialLoad: boolean = true;
  closeResult = '';
  editData: any;
  deleteData: any;
  users: any = [];
  currencyCode: any;
  pipelines: any = [];
  pipelineData: any = [];

  current_id_hover = '';
  infoArray: any = [];
  isToggled: boolean = false;
  isSaving: boolean = false;

  displayedColumns: string[] = [
    "deal_name",
    "info",
    "amount",
    // "pipeline",
    "deal_owner",
    "lead_source",
    // "client_name",
    "account_name",
    "close_date",
    "probability",
    "deal_stage",
    "deal_type",
    "deal_priority",
    // "next_step",
    "action"
  ];




  defaultFilter = [
    "deal_name",
    "deal_stage",
    "amount",
    "lead_source",
    "client_name",
    "account_name",
    "close_date",
    "priority",
    "probability",
    "next_step",
  ]

  info = [
    { key: 'deal_name', value: 'Deal Name' },
    { key: 'account_name', value: 'Account Name' },
    { key: 'deal_stage', value: 'Deal Stage' },
    { key: 'amount', value: 'Amount' },
    {key:'description',value:'Description'},
    { key: 'lead_source', value: 'Lead Source' },
    { key: 'close_date', value: 'Close Date' },
    { key: 'probability', value: 'Probability' },
    { key: 'deal_type', value: 'Deal Type' },
    {key:'Owner',value:'Deal Owner'},
    { key: 'deal_priority', value: 'Deal Priority' },
    { key: 'next_step', value: 'Next Step' },
    { key: 'pipeline', value: 'Pipeline' },
    { key: 'createdAt', value: 'Created At' },
  ];

  constructor(
    private localStorage: LocalStorageService,
    private api: DealService,
    private pipelineApi: PipeLineService,
    // private userApi: UsersService,
    private modalService: NgbModal,
    private toast: ToastrService,
    private userApi: UsersService

  ) { }

  handleShowCanban(event:any) {
    this.isToggled = event;
  }


  ngAfterViewInit() {
    console.log("tabl column : ", this.displayedColumns);
    this.loadData();
    this.currencyCode = this.localStorage.getClientCurrency();
  }

  ngOnInit(): void {
    this.getAllUsers();
    this.getAllPipeline();

    for (let index = 0; index < this.info.length; index++) {
      this.infoArray.push({ key: this.info[index]?.key, value: this.info[index]?.value });
    }
  }

  getAllPipeline() {
    let param = {
      client_id: this.localStorage.getClientId(),
    };
    this.pipelineApi.getAllPipeLine(param).subscribe({
      next: (res: any) => {
        this.pipelineData = res || [];

        this.pipelines = this.pipelineData.map((item: any) => item.pipeline_name);

      },
      error: (e: any) => {
        console.error(e);
      },
    });
  }


  getAllUsers() {
    let param = {
      client_id: this.localStorage.getClientId(),
    };
    this.userApi.findAllUsers(param).subscribe({
      next: (res: any) => {
        this.users = res || [];
      },
      error: (e: any) => {
        console.error(e);
      },
    });
  }


  open(content: any) {
    this.formAction = 'Add';

    this.modalService.open(content, {
      size: 'lg', scrollable: true,
      centered: true
    })
  }

  loadData() {

    try {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(
        this.dateQuery.valueChanges,
        this.searchQuery.valueChanges,
        this.sort.sortChange,
        this.paginator.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            this.reloading = true;
            console.log("client_id", this.localStorage.getClientId())
            console.log("dateQuery", this.dateQuery.value)
            let param = {
              "page": this.paginator.pageIndex * this.paginator.pageSize,
              "limit": this.paginator.pageSize,
              where: {
                client_id: this.localStorage.getClientId(),
              },
              "order": {
                "col": "\"deal\".\"updatedAt\"",
                "dir": "DESC"
              }
            }

            if (this.searchQuery.value) {

              for (let [key, value] of Object.entries(this.searchQuery.value)) {

                if (this.defaultFilter.includes(key)) {
                  key = (key == 'assign_to') ? 'assign_to' : key
                  const obj = {
                    ...param.where,
                    [key]: value,
                  };
                  param.where = obj;
                }
              }

            }

            return this.api
              .getAllDeal(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            this.initialLoad = false;
            this.reloading = false;
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            console.log("response", response.length)
            return response?.rows;
          })
        )
        .subscribe({
          next: (data) => {
            this.dataSource = new MatTableDataSource<any>(data);
          },
          error: (e) => {
            this.initialLoad = false;
            this.reloading = false;
            console.error(e);
          },
          complete: () => {
            this.reloading = false;
            this.initialLoad = false;
          },
        });
    } catch (e) {
      this.initialLoad = false;
      this.reloading = false;
      console.error(e);
    }
  }

  reloadData() {
    this.reloading = true;
    try {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            this.reloading = true;

            console.log("client_id", this.localStorage.getClientId())
            let param = {
              "page": this.paginator.pageIndex * this.paginator.pageSize,
              "limit": this.paginator.pageSize,
              where: {
                client_id: this.localStorage.getClientId(),
              },
              "order": {
                "col": "\"deal\".\"updatedAt\"",
                "dir": "DESC"
              }
            }

            return this.api
              .getAllDeal(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            this.initialLoad = false;
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            console.log("response", response.length)
            return response?.rows;
          })
        )
        .subscribe({
          next: (data) => {
            this.dataSource = new MatTableDataSource<any>(data);
          },
          error: (e) => {
            this.initialLoad = false;
            this.reloading = false;
            console.error(e);
          },
          complete: () => {
            this.initialLoad = false;
            this.reloading = false;
          },
        });
    } catch (e) {
      this.initialLoad = false;
      this.reloading = false;
      console.error(e);
    }
  }

  openEdit(content: any, data: any) {
    this.formAction = 'Update';
    this.editData = data;
    this.modalService.open(content, {
      size: 'lg', scrollable: true,
      centered: true
    })
  }

  openDeleteEvent(content: any, data: any) {
    this.deleteData = data;
    this.modalService.open(content, {
      size: 'md', scrollable: true,
      centered: true
    })
  }

  searchFilter(query: any) {
    console.log('Search Query:', query);
    this.searchQuery.setValue(query);
  }

  handleDeleteCompany() {

    const parmas = {
      "deal_id": this.deleteData.deal_id
    }
    this.btnLoading = true;
    this.api.deleteDeal(parmas).subscribe((res: any) => {
      console.log('Response:', res);
      this.toast.success('Company Deleted Successfully');
      this.reloadData();
      this.btnLoading = false;
      this.modalService.dismissAll();
    })
  }

  updateHoverState(id: string): void {
    this.current_id_hover = id;
  }

  updateStatus(data: any) {
    console.log('Update Status:', data);
    this.isSaving = true;
  
    this.api.updateDeal(data).subscribe({
      next: (res: any) => {
        console.log('Response:', res);
        // this.toast.success('Deal Updated Successfully');
      },
      error: (err: any) => {
        console.error('Error updating deal:', err);
        this.toast.error('Failed to update the deal. Please try again.');
      },
      complete: () => {
        this.isSaving = false; // Ensure it's set to false regardless of success or failure
      }
    });
  }
  


}

function observableOf(arg0: null): any {
  console.log(arg0);
  throw new Error('Function not implemented.');
}
