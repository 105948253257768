import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ContactsService } from '../../services/contacts.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { of as observableOf } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/private/services/users.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements AfterViewInit {
  form: any = 'create';
  btnLoading = false;
  deleteParam: any = {};
  totalRows = 0;
  pageSize = 25;
  currentPage = 0;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  formAction: string = 'Create';
  editData: any;
  current_id_hover = '';
  infoArray: any = [];
  displayedColumns: string[] = [
    // 'contact_id',
    'fname',
    "info",
    // 'lname',
    'email',
    // 'secondary_email',
    // 'description',
    'phone',
    'mobile',
    // 'fax',
    // 'title',
    'department',
    // 'address',
    'source',
    // 'dob',
    // 'createdAt',
    // 'updatedAt',
    'owner',
    'account_name',
    'action',
  ];

  

  defaultFilter = [
    'fname',
    'lname',
    'email',
    'description',
    'phone',
    'mobile',
    'fax',
    'title',
    'department',
    'source',
    'dob',
    'createdAt',
    'owner',
    'account_name',
    'action'
  ];

  info: any = [
    {
      key: 'first_name',
      value: 'First Name',
    },
    {
      key: 'last_name',
      value: 'Last Name',
    },
    {
      key: 'email',
      value: 'Email',
    },
    {
      key: 'description',
      value: 'Description',
    },
    {
      key: 'phone',
      value: 'Phone',
    },
    {
      key: 'mobile',
      value: 'Mobile',
    },
    {
      key: 'fax',
      value: 'Fax',
    },
    {
      key: 'title',
      value: 'Title',
    },
    {
      key: 'department',
      value: 'Department',
    },
    {
      key: 'lead_source',
      value: 'Source',
    },
    {
      key: 'dob',
      value: 'Date of Birth',
    },
    {
      key: 'createdAt',
      value: 'Created At',
    },
    {
      key: 'updatedAt',
      value: 'Updated At',
    },
    {
      key: 'Owner',
      value: 'Owner',
    },
   
  ]
  

  
  createContact: any = new Contact();
  loading = true;
  reloading = false;
  discription :string = '';
  users: any = [];

  constructor(
    private api: ContactsService,
    private localStorage: LocalStorageService,
    private modalService: NgbModal,
    private toast: ToastrService,
    private userApi: UsersService
  ) {}

  ngOnInit() {
    for (let index = 0; index < this.info.length; index++) {
      this.infoArray.push({key:this.info[index]?.key,value:this.info[index]?.value});
    }
  }

  ngAfterViewInit() {
    this.loadData();
    this.getAllUsers();
  
   
  }



  getAllUsers() {
    let param = {
      client_id: this.localStorage.getClientId(),
    };
    this.userApi.findAllUsers(param).subscribe({
      next: (res: any) => {
        this.users = res || [];
      },
      error: (e: any) => {
        console.error(e);
      },
    });
  }

  searchFilter(query: any) {
    console.log('Search Query:', query);
    this.searchQuery.setValue(query);
  }



  loadData() {
    console.log('loadData');
    try {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      console.log('this.paginator', this.paginator);
      merge(
        this.searchQuery.valueChanges,
        this.sort.sortChange,
        this.paginator.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            this.reloading = true;
            let param:any = {
              where: {
                client_id: parseInt(this.localStorage.getClientId()),
              },
              page: this.paginator.pageIndex * this.paginator.pageSize,
              limit: this.paginator.pageSize,
              "order": {
                "col": "\"contact\".\"updatedAt\"",
                "dir": "DESC"
            },
            };

            if (this.searchQuery.value) {
              param.filter = this.searchQuery.value;
            }

            return this.api
              .getAllContacts(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            if (response === null) {
              this.loading = false;
              this.reloading = false;
              return [];
            }
            this.totalRows = response.count;
            return response?.rows || [];
          })
        )
        .subscribe({
          next: (data) => {
            console.log('data', data);
            this.loading = false;
            this.reloading = false;
            this.dataSource = new MatTableDataSource<any>(data);
          },
          error: (e) => {
            console.error(e);
            this.loading = false;
            this.reloading = false;
          },
          complete: () => {
            this.loading = false;
            this.reloading = false;
          },
        });
    } catch (e) {
      console.error(e);
      this.loading = false;
      this.reloading = false;
    }
  }

  reloadData() {
    try {

      merge(
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            this.reloading = true;
            let param :any= {
              where: {
                client_id: parseInt(this.localStorage.getClientId()),
              },
              page: this.paginator.pageIndex * this.paginator.pageSize,
              limit: this.paginator.pageSize,
              "order": {
                "col": "\"contact\".\"updatedAt\"",
                "dir": "DESC"
            },
            };

            if (this.searchQuery.value) {
              param.filter = this.searchQuery.value;
            }
            return this.api
              .getAllContacts(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            if (response === null) {
              this.reloading = false;
              return [];
            }
            this.totalRows = response.count;
          return response?.rows || [];
          })
        )
        .subscribe({
          next: (data) => {
            console.log('data', data);
            this.dataSource = new MatTableDataSource<any>(data);
          },
          error: (e) => {
            console.error(e);
            this.reloading = false;
          },
          complete: () => {
            this.reloading = false;
          },
        });
    } catch (e) {
      console.error(e);
      this.reloading = false;
    }
  }


  editForm(element: any,content: any) {
    this.formAction = 'Update';

    this.editData = element;
    this.modalService.open(content, {
      size: 'lg',
      scrollable: true,
      centered: true,
    });
  }


  open(content: any) {
    this.form = 'create';
    this.formAction = 'Create';
    this.createContact = new Contact();
    this.modalService.open(content, {
      size: 'lg',
      scrollable: true,
      centered: true,
    });
  }

  create() {
    this.btnLoading = true;
    this.createContact.client_id = this.localStorage.getClientId();
    this.createContact.user_id = this.localStorage.getUserId();
    this.createContact.center_id = this.localStorage.getCenterId();
    this.api.createContact(this.createContact).subscribe({
      next: (res: any) => {
        this.btnLoading = false;
        this.modalService.dismissAll();
        this.toast.success('Contact created successfully');
        this.loadData();
      },
      error: (err: any) => {
        this.btnLoading = false;
        this.toast.error('Error creating contact');
      },
    });
  }

  update() {
    this.btnLoading = true;
    this.api.updateContact(this.createContact).subscribe({
      next: (res: any) => {
        this.btnLoading = false;
        this.modalService.dismissAll();
        this.toast.success('Contact updated successfully');
        this.loadData();
      },
      error: (err: any) => {
        this.btnLoading = false;
        this.toast.error('Error updating contact');
      },
    });
  }

  deleteModal(content: any, id: any) {
    let param = {
      contact_id: id,
      client_id: this.localStorage.getClientId(),
    };
    this.deleteParam = param;
    this.modalService.open(content, {
      size: 'l',
      scrollable: true,
      centered: true,
    });
  }

  handleDeleteEvent() {
    this.btnLoading = true;
    const payload = {
      contact_id: this.deleteParam.contact_id,
    };
    this.api.deleteContact(payload).subscribe({
      next: (res: any) => {
        this.reloading = false;
        this.modalService.dismissAll();
        this.reloadData();
        this.btnLoading = false;
        this.toast.success('Contact deleted successfully');
      },
      error: (err: any) => {
        console.error(err);
        this.reloading = false;
        this.btnLoading = false;
        this.toast.error('Error deleting contact');
      },
    });
  } 


  openDescriptionModal(content: any, description: string): void {
    this.discription = description;
    this.modalService.open(content, {
      size: 'md',
      scrollable: true,
      centered: true,
    });
  }

  updateHoverState(id: string): void {
    this.current_id_hover = id;
  }
}


export class Contact {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  description: string;
  phone: string;
  address: string;
  source: string;
  status: string;
  user_id: number;
  client_id: number;
  center_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  constructor() {
    this.id = 0;
    this.first_name = '';
    this.last_name = '';
    this.email = '';
    this.description = '';
    this.phone = '';
    this.address = '';
    this.source = '';
    this.status = '';
    this.user_id = 0;
    this.client_id = 0;
    this.center_id = 0;
    this.created_at = '';
    this.updated_at = '';
    this.deleted_at = '';
  }
}