import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, debounceTime, distinctUntilChanged, map, merge, startWith, switchMap } from 'rxjs';
import { EmailHubService } from 'src/app/private/services/email-hub.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-mail-middle-section',
  templateUrl: './mail-middle-section.component.html',
  styleUrls: ['./mail-middle-section.component.scss'],
})
export class MailMiddleSectionComponent {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @Input() activeAction: string = 'inbox';
  @Output() selecteRow = new EventEmitter<any>();
  searchQuery = new FormControl();
  top = 10;
  skipToken: any = '';
  skip = 0;
  loadingData = false;

  loading = true;

  dataSource: any[] = [];

  selectedRow: any;

  toasters: any;

  selectedIds: any[] = [];

  btnLoading = false;

  hoverelmentId: any = null;
  loadMore = false;
  currentLimit = 10;
  maxLimit = 10;
  currentUser: any;

  constructor(
    private api: EmailHubService,
    private toast: ToastrService,
    private localStorage: LocalStorageService,
    private modalService: NgbModal

  ) { }


  ngOnInit() {
    this.loadData();
    this.currentUser = this.localStorage.getProfileName()
  }

  getSkipToken(url: any) {
    const params = new URL(url).searchParams; // Parse the query parameters
    return params.get('$skiptoken'); // Extract the skiptoken parameter
  }


  loadData() {

    console.log("activeAction", this.activeAction);

    try {
      merge(
        this.searchQuery.valueChanges.pipe(
          debounceTime(1000), // Wait for 300ms after the last keystroke
          distinctUntilChanged() // Only trigger if the value has changed
        
      )
      )
        .pipe(
          startWith({}),
          switchMap(() => {

            if(this.searchQuery.value === ''){
              this.skipToken = '';
            }

            if(this.searchQuery.value || this.skipToken) {
              this.skip = 0;
            }


            let param = {
              "client_id": this.localStorage.getClientId(),
              "user_email": this.localStorage.getEmail(),
              "searchKeyword": this.searchQuery.value,
              "requestType": this.searchQuery.value ? "search" : "normal",
              "top": this.top,
              "skip": 0,
              "skiptoken": this.skip > 0 ? this.skipToken : ""
            }

            this.loadingData = true;

            if (this.activeAction == 'inbox') {
              return this.api
                .getOutlookInboxEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'sent') {
              return this.api
                .getOutlookSentEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'drafts') {
              return this.api
                .getOutlookDraftEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'trash') {
              return this.api
                .getOutlookTrashEmail(param)
                .pipe(catchError(() => observableOf(null
                )));
            }
            else {
              return this.api
                .getOutlookDeletedEmail(param)
                .pipe(catchError(() => observableOf(null)));
            }

          }),
          map((response: any) => {
            this.loading = false;
            if (response?.value === null) {
              return [];
            }
            this.skipToken = response['@odata.nextLink'];

            if (this.searchQuery.value && this.skipToken) {
              const skipToken = this.getSkipToken(this.skipToken);;
              this.skipToken = skipToken;
              this.skip = 0;
            } else {
              this.skipToken = "";
              this.skip = this.top;
            }

            if(this.activeAction === 'inbox'){
              this.maxLimit = response?.totalInboxEmailCount
            } else if(this.activeAction === 'sent'){
              this.maxLimit = response?.totalSentEmailCount
            }else if(this.activeAction === 'drafts'){
              this.maxLimit = response?.totalDraftEmailCount
            }else if(this.activeAction === 'trash'){
              this.maxLimit = response?.totalJunkEmailCount
            }else if(this.activeAction === 'deleted'){
              this.maxLimit = response?.totalDeletedEmailCount
            }

            return response?.value;
          })
        )
        .subscribe({
          next: (data) => {
            this.loadingData = false;
            this.dataSource = data;
           
          },
          error: (e) => {
            this.loading = false;
            this.loadingData = false;
            console.error(e);
          },
          complete: () => {
            this.loading = false;
          },
        });
    } catch (e) {
      this.loading = false;
      console.error(e);
    }
  }

  loadDataMore() {
    try {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            let param = {
              "client_id": this.localStorage.getClientId(),
              "user_email": this.localStorage.getEmail(),
              "searchKeyword": this.searchQuery.value,
              "requestType": this.searchQuery.value ? "search" : "normal",
              "top": this.top,
              "skip": this.skip,
              "skiptoken":  this.skipToken 
            }

            this.loadMore = true;

            if (this.activeAction == 'inbox') {
              return this.api
                .getOutlookInboxEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'sent') {
              return this.api
                .getOutlookSentEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'drafts') {
              return this.api
                .getOutlookDraftEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'trash') {
              return this.api
                .getOutlookTrashEmail(param)
                .pipe(catchError(() => observableOf(null
                )));
            }
            else {
              return this.api
                .getOutlookDeletedEmail(param)
                .pipe(catchError(() => observableOf(null)));
            }

          }),
          map((response: any) => {
            this.loadMore = false;
            if (response?.value === null) {
              return [];
            }
            this.skipToken = response['@odata.nextLink'];
            if (this.searchQuery.value && this.skipToken) {
              const skipToken = this.getSkipToken(this.skipToken);;
              this.skipToken = skipToken;
            } else {
              this.skipToken = "";
            }
            return response?.value;
          })
        )
        .subscribe({
          next: (data) => {
            this.loadingData = false;
            this.loadMore = false;
            this.dataSource = [...this.dataSource, ...data];
            this.skip = this.skip + this.top;
          },
          error: (e) => {
            this.loading = false;
            this.loadingData = false;
            this.loadMore = false;
            console.error(e);
          },
          complete: () => {
            this.loadMore = false;
            this.loading = false;
          },
        });
    } catch (e) {
      this.loadMore = false;
      this.loading = false;
      console.error(e);
    }
  }

  refetch() {


    try {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            // console.log("client_id", this.localStorage.getClientId())
            // this.toasters = this.toast.warning('Loading...', '', {
            //   disableTimeOut: true, // Keep the toast open indefinitely
            // });

            this.loadingData = true;
            let param = {
              "client_id": this.localStorage.getClientId(),
              "user_email": this.localStorage.getEmail(),
              "searchKeyword": this.searchQuery.value,
              "requestType": this.searchQuery.value ? "search" : "normal",
              "top": this.top,
              "skip": 0,
              "skiptoken":this.skipToken 
            }

            if (this.activeAction == 'inbox') {
              return this.api
                .getOutlookInboxEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'sent') {
              return this.api
                .getOutlookSentEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'drafts') {
              return this.api
                .getOutlookDraftEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'trash') {
              return this.api
                .getOutlookTrashEmail(param)
                .pipe(catchError(() => observableOf(null
                )));
            }
            else {
              return this.api
                .getOutlookDeletedEmail(param)
                .pipe(catchError(() => observableOf(null)));
            }

          }),
          map((response: any) => {
            this.loading = false;
            if (response?.value === null) {
              return [];
            }
            return response?.value;
          })
        )
        .subscribe({
          next: (data) => {
            this.dataSource = data;
            this.skip = this.top;
            this.toast.clear(this.toasters.toastId);
            this.loadingData = false;

          },
          error: (e) => {
            this.loading = false;
            this.loadingData = false;

            console.error(e);
          },
          complete: () => {
            this.loadingData = false;
            this.loading = false;
          },
        });
    } catch (e) {
      this.loading = false;
      this.loadingData = false;

      console.error(e);
    }
  }



  onScroll(container: HTMLElement) {
    console.log('scrolling');
    const threshold = 300; // Adjust based on your needs
    const { scrollTop, scrollHeight, clientHeight } = container;

    if (scrollTop + clientHeight >= scrollHeight - threshold && !this.loading && !this.loadingData && !this.loadMore

      && this.dataSource.length < this.maxLimit
    ) {
      this.loadDataMore();
    }
  }

  setSeelctRow(row: any) {
    this.selectedRow = row;
    this.selecteRow.emit(row);
  }

  updateSelection(element: any): void {
    console.log('Element:', element);

    const index = this.selectedIds.indexOf(element.id);

    if (index === -1) {
      this.selectedIds.push(element.id);
    } else {
      this.selectedIds.splice(index, 1);
    }

    console.log('Selected IDs:', this.selectedIds);
  }

  openDeleteGroup(content: any) {
    this.modalService
      .open(content, { size: 'md', scrollable: true, centered: true })
  }

  openDeleteGroup2(content: any, id: any) {
    this.selectedIds = [id];
    this.modalService
      .open(content, { size: 'md', scrollable: true, centered: true })
  }

  handleDeleteEvent() {
    
    this.btnLoading = true;
    let param = {
      "client_id": this.localStorage.getClientId(),
      "user": this.localStorage.getEmail(),
      "messageids": this.selectedIds
    }

    if (this.activeAction == 'deleted') {
      this.api.deleteOutlookEmailPermanently(param).subscribe({
        next: (res) => {
          setTimeout(() => {
            this.btnLoading = false;

            this.modalService.dismissAll();
            this.toast.success('Email deleted successfully');
          }, 1500);
          this.refetch();
          this.selectedIds = [];
        },
        error: (e) => {
          console.error(e);
          this.toast.error('Error while deleting email');
          this.btnLoading = false;
        }
      })
    } else {
      this.api.deleteOutlookEmail(param).subscribe({
        next: (res) => {
          setTimeout(() => {
            this.btnLoading = false;
            this.modalService.dismissAll();
            this.toast.success('Email deleted successfully');
          }, 1500);
          this.refetch();
          this.selectedIds = [];
        },
        error: (e) => {
          console.error(e);
          this.toast.error('Error while deleting email');
          this.btnLoading = false;
        }
      })
    }
  }

  getInitials(name: string): string {
    if (!name) {
      return '';
    }
  
    // Split the name into parts
    const nameParts = name.trim().split(' ');
  
    // Get the first letter of the first two parts only
    const initials = nameParts
      .slice(0, 2) // Take only the first two parts
      .map(part => part[0]?.toUpperCase()) // Get the first letter of each part
      .join('');
  
    return initials;
  }
  
}

function observableOf(arg0: null): any {
  console.log(arg0);
  throw new Error('Function not implemented.');
}