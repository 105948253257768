import { Component, EventEmitter, Input, Output } from '@angular/core';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-signature-model',
  templateUrl: './signature-model.component.html',
  styleUrls: ['./signature-model.component.scss']
})
export class SignatureModelComponent {

  @Input() type = 'Add';
  signatures: string[] = [];
  @Input() selectedIndex: number | null = null;
  @Output() close = new EventEmitter<boolean>();
  @Output() updateSignatures = new EventEmitter<string[]>();
  btnLoading: boolean = false;
  public Editor = ClassicEditor as unknown as any;

   constructor(
      private toast: ToastrService
    ) { 
     
    }

  editorConfig = {
  
    enterMode: 'SHIFT+ENTER',
    extraPlugins: [],  // Add Image and ImageResize plugins
    imageResizeOptions: [
      { name: 'resizeImage:original', value: null, icon: 'original' },
      { name: 'resizeImage:50', value: '50', icon: '50' },
      { name: 'resizeImage:75', value: '75', icon: '75' },
      { name: 'resizeImage:100', value: '100', icon: '100' }
    ]
  };
  newSignature: string = '';

  addSignature() {
    if (this.newSignature.trim() && this.signatures.length < 3) {
      this.signatures.push(this.newSignature.trim());
    }
  }

  saveSignature() {

    if(this.signatures.length < 1) {
      this.toast.error('Please add signature');
      return;
    }

    this.newSignature = '';
    this.updateSignatures.emit(this.signatures);
    this.closeModal();
  }


  deleteSignature(index: number) {
    this.signatures.splice(index, 1);
  }

  closeModal() {
    this.close.emit();
  }

   onReady(eventData:any) {
      eventData.plugins.get('FileRepository').createUploadAdapter = function (loader:any) {
        console.log(btoa(loader.file));
        return new UploadAdapter(loader);
      };
  
      eventData.plugins.get('ImageResize').enable({
        handleSize: 50,
        handleStyles: {
          display: 'block',
          'background-color': 'black',
          'border-radius': '50%',
          'border': '1px solid white',
          'box-shadow': '0 0 0 2px black',
          'box-sizing': 'content-box',
          'height': '16px',
          'width': '16px'
        }
      });
  
      // editorObj.keystrokes.set('Shift+Enter', 'enter');
      // editor.value = editorObj;
  
      eventData.keystrokes.set('Shift+Enter', 'enter');
  
    }

}


export class UploadAdapter {
  private loader: any;

  constructor(loader: any) {
    this.loader = loader;
  }

  public async upload(): Promise<any> {
    return this.loader.file
      .then((file: File) => this.readThis(file))
      .then((dataUrl:any) => {
        return { default: dataUrl };
      });
  }

  readThis(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }
}