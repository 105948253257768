import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { CompanyService } from 'src/app/private/services/company.service';
import { UsersService } from 'src/app/private/services/users.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent {

  searchQuery = new FormControl();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;

  dateQuery = new FormControl();
  center_id: any;
  totalRows = 0;
  temp: any = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  formAction = 'Add';
  currentPage = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [10, 25, 100];
  btnLoading: boolean = false;
  reloading: boolean = false;
  initialLoad: boolean = true;
  closeResult = '';
  editData: any;
  deleteData: any;
  current_id_hover = '';
  infoArray: any = [];
  users: any = [];

  displayedColumns: string[] = [
    "account_name",
    "info",
    // "account_number",
    "account_owner",
    "account_site",
    "account_type",
    // "annual_revenue",
    // "employees",
    "gst_no",
    // "tan_no",
    // "ownership",
    "industry",
    // "rating",
    "email",
    "phone_number",
    // "website",
    "status",
    "action"
  ];

  defaultFilter = [
    'account_name',
    'city',
    'state',
    'country',
    'postal_code',
    'email',
    'phone_number',
    'website',
    'industry',
    'status'
  ]

  info :any = [
    {
      key:'account_name',
      value:'Account Name'
    },
    {
      key:'account_number',
      value:'Account Number'
    },
    {
      key:'description',
      value:'Description'
    },
    {
      key:'Owner',
      value:'Account Owner'
    },
    {
      key:'account_site',
      value:'Account Site'
    },
    {
      key:'account_type',
      value:'Account Type'
    },
    {
      key:'annual_revenue',
      value:'Annual Revenue'
    },
    {
      key:'employees',
      value:'Employees'
    },
    {
      key:'gst_no',
      value:'GST No'
    },
    {
      key:'tan_no',
      value:'TAN No'
    },
    {
      key:'ownership',
      value:'Ownership'
    },
    {
      key:'industry',
      value:'Industry'
    },
    {
      key:'rating',
      value:'Rating'
    },
    {
      key:'email',
      value:'Email'
    },
    {
      key:'phone_number',
      value:'Phone Number'
    },
    {
      key:'website',
      value:'Website'
    },
    {
      key:'status',
      value:'Status'
    },
    {
      key:'billing_address',
      value:'Billing Address'
    },
    {
      key:'shipping_address',
      value: 'Shipping Address'
    },
    {
      key:'sic_code',
      value:'SIC Code'
    },
    {
      key: 'ParentAccount',
      value: 'Parent Account'
    }
  ]

  constructor(
    private localStorage: LocalStorageService,
    private api: CompanyService,
    // private userApi: UsersService,
    private modalService: NgbModal,
    private toast: ToastrService,
    private userApi: UsersService


  ) { }

  ngOnInit(): void {
    this.getAllUsers();

    for (let index = 0; index < this.info.length; index++) {
      this.infoArray.push({key:this.info[index]?.key,value:this.info[index]?.value});
    }

  }


  getAllUsers() {
    let param = {
      client_id: this.localStorage.getClientId(),
    };
    this.userApi.findAllUsers(param).subscribe({
      next: (res: any) => {
        this.users = res || [];
      },
      error: (e: any) => {
        console.error(e);
      },
    });
  }

  ngAfterViewInit() {
    console.log("tabl column : ", this.displayedColumns);
    this.loadData();
  }


  open(content: any) {
    this.formAction = 'Add';

    this.modalService.open(content, {
      size: 'xl', scrollable: true,
      centered: true
    })
  }

  loadData() {

    try {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(
        this.dateQuery.valueChanges,
        this.searchQuery.valueChanges,
        this.sort.sortChange,
        this.paginator.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            this.reloading = true;
            console.log("client_id", this.localStorage.getClientId())
            console.log("dateQuery", this.dateQuery.value)
            let param = {
              "page": this.paginator.pageIndex * this.paginator.pageSize,
              "limit": this.paginator.pageSize,
              where: {
                client_id: this.localStorage.getClientId(),
              },
              "order": {
                "col": "\"account\".\"updatedAt\"",
                "dir": "DESC"
              }
            }

            if (this.searchQuery.value) {

              for (let [key, value] of Object.entries(this.searchQuery.value)) {

                if (this.defaultFilter.includes(key)) {
                  key = (key == 'assign_to') ? 'assign_to' : key
                  const obj = {
                    ...param.where,
                    [key]: value,
                  };
                  param.where = obj;
                }
              }

            }

            return this.api
              .getAllCompany(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            this.initialLoad = false;
            this.reloading = false;
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            console.log("response", response.length)
            return response?.rows;
          })
        )
        .subscribe({
          next: (data) => {
            this.dataSource = new MatTableDataSource<any>(data);
          },
          error: (e) => {
            this.initialLoad = false;
            this.reloading = false;
            console.error(e);
          },
          complete: () => {
            this.initialLoad = false;
            this.reloading = false;
          },
        });
    } catch (e) {
      this.initialLoad = false;
      this.reloading = false;
      console.error(e);
    }
  }

  reloadData() {
    this.reloading = true;
    try {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            console.log("client_id", this.localStorage.getClientId())
            let param = {
              "page": this.paginator.pageIndex * this.paginator.pageSize,
              "limit": this.paginator.pageSize,
              where: {
                client_id: this.localStorage.getClientId(),
              },
              "order": {
                "col": "\"account\".\"updatedAt\"",
                "dir": "DESC"
              }
            }

            return this.api
              .getAllCompany(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            this.initialLoad = false;
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            console.log("response", response.length)
            return response?.rows;
          })
        )
        .subscribe({
          next: (data) => {
            this.dataSource = new MatTableDataSource<any>(data);
          },
          error: (e) => {
            this.initialLoad = false;
            this.reloading = false;
            console.error(e);
          },
          complete: () => {
            this.initialLoad = false;
            this.reloading = false;
          },
        });
    } catch (e) {
      this.initialLoad = false;
      this.reloading = false;
      console.error(e);
    }
  }

  openEdit(content: any, data: any) {
    this.formAction = 'Update';
    this.editData = data;
    this.modalService.open(content, {
      size: 'lg', scrollable: true,
      centered: true
    })
  }

  openDeleteEvent(content: any, data: any) {
    this.deleteData = data;
    this.modalService.open(content, {
      size: 'md', scrollable: true,
      centered: true
    })
  }

  searchFilter(query: any) {
    console.log('Search Query:', query);
    this.searchQuery.setValue(query);
  }

  handleDeleteCompany() {

    const parmas = {
      account_id: this.deleteData.account_id
    }
    this.btnLoading = true;
    this.api.deleteCompany(parmas).subscribe((res: any) => {
      console.log('Response:', res);
      this.toast.success('Company Deleted Successfully');
      this.reloadData();
      this.btnLoading = false;
      this.modalService.dismissAll();
    })
  }

  updateHoverState(id: string): void {
    this.current_id_hover = id;
  }



}

function observableOf(arg0: null): any {
  console.log(arg0);
  throw new Error('Function not implemented.');
}