import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject, filter, map, mergeMap, takeUntil } from 'rxjs';
import { TrackingService } from 'src/app/private/services/tracking.service';
import { UsersService } from 'src/app/private/services/users.service';
import { AuthService } from 'src/app/public/services/auth.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Output() toggleMenuEvent = new EventEmitter<void>();
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  profileName: any;
  logo: any;
  currentTheme = 'default';
  currentTitle: any
  selectedCenter: any = ""
  centerList: any = [];
  spaciality: any = "";
  formAction = 'add'
  user: User = new User();
  isSubmitted: boolean = false
  departmentsList: any;
  roles: any;
  centers: any;
  isLoading: boolean = false;
  isCenter: boolean = false;
  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
  signatures: any[] = [];
  selectedIndex: number | null = null;


  constructor(private tracker: TrackingService, private userApi: UsersService, private localStorage: LocalStorageService, private router: Router, private activatedRoute: ActivatedRoute, private auth: AuthService, private modalService: NgbModal, private toast: ToastrService) {
    this.profileName = localStorage.getProfileName();
    this.selectedCenter = localStorage.getCenterId();
    this.isCenter = localStorage.getIsCenter();
    this.spaciality = "CRM";

    this.router.events.pipe(
      filter((ev) => ev instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route: any) => {
        let title = route.data['title'] || '';
        while (route.firstChild) {
          route = route.firstChild;
          title += route.data['title'];
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe((event: any) => this.currentTitle = event['title']);
  }

  public toggleMenu() {
    this.toggleMenuEvent.emit();
  }
  changeCenter(event: any) {
    let center_id = event.target.value;
    if (center_id) {
      localStorage.setItem('center_id', center_id);
      location.reload();
    }
  }
  ngOnInit() {
    // this.currentTitle = this.route.data['title'] ;
    this.logo = this.localStorage.getClientLogo() || "assets/img/img.png"
    let param = {
      where: {
        client_id: this.localStorage.getClientId(),
      }
    }
    this.userApi.getAllCenter(param).subscribe({
      next: (res) => {
        if (res) {
          this.centerList = res;
          this.selectedCenter = this.localStorage.getCenterId();
        }
      },
      error: (e) => { }
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
  }

  toggleSidebar(): boolean {
    return false;
  }

  navigateHome() {
    return false;
  }
  logout() {
    this.tracker.track("Logout", "Authentication", "User Logout", null)
    setTimeout(() => {
      this.auth.logOut();
      this.toast.success("User Logout Successfully")
      this.router.navigate(['login'])
    }, 1000);

  }

  saveUser(isValid: any) {
    this.isSubmitted = true;
    if (isValid) {
      this.isLoading = true;

      const { password, ...userWithoutPassword } = this.user;


      let user_sign: any[] = [];

      this.signatures.forEach((element, index) => {
        user_sign.push({
          signature: element,
          isActive: index === this.selectedIndex ? true : false
        })
      }
      );

      console.log("user_sign", user_sign);

      const payload = {
        ...userWithoutPassword,
        user_sign: user_sign,
      };

      this.userApi.updateUser(payload).subscribe({
        next: (res: any) => {
          this.getUser();
          this.modalService.dismissAll();
          localStorage.setItem('user_sign', JSON.stringify(user_sign));
          this.toast.success("User Updated Successfully", 'Success')
          this.isLoading = false;
        },
        error: (e: any) => {
          this.toast.error(e.error.msg, 'Error')
          this.isLoading = false;
        }
      })
    }
  }

  loadRoles() {
    let param = {
      where: {
        // center_id: parseInt(this.localStorage.getCenterId()),
        client_id: parseInt(this.localStorage.getClientId())
      }

    }
    this.userApi.getAllRoles(param).subscribe({
      next: (res) => {
        console.log("role", res)
        this.roles = res;
      }
    })
  }
  getCenters() {
    let param = {
      where: {
        client_id: this.localStorage.getClientId(),
      }

    }
    this.userApi.getAllCenter(param).subscribe({
      next: (res) => {
        this.centers = res;
      }
    })
  }

  loadDepartments() {
    let param = {
      where: {
        // center_id:this.localStorage.getCenterId(),
        client_id: this.localStorage.getClientId(),
      }

    }
    this.userApi.getAllDepartment(param).subscribe({
      next: (res) => {
        this.departmentsList = res;
      }
    })
  }

  open(content: any) {
    this.getCenters();
    // this.loadDepartments();
    // this.loadRoles();

    Promise.all([this.loadDepartments(), this.loadRoles()])
      .then(() => {
        this.getUser();
        this.user = new User();
        this.formAction = "add";
        this.modalService.open(content, { size: 'xl', scrollable: true });
      })
      .catch(error => {
        console.error('Error loading departments or roles:', error);
      });


    // setTimeout(() => {
    //   this.getUser();
    //   this.user = new User();
    // }, 3000);

    

  }

  getUser() {
    const params = {
      user_id: this.localStorage.getUserId(),
    };

    this.userApi.getUser(params).subscribe({
      next: (res: any) => {
        this.user = res;
        this.signatures = res.user_sign.map((sign: any) => sign.signature);
        this.selectedIndex = res.user_sign.findIndex((sign: any) => sign.isActive);
      },
      error: (e: any) => {
        console.log(e);
      },
    });
  }

  openSignatureModal(
    content: any,
  ) {

    if (this.signatures.length >= 3) {
      this.toast.error('You can add only 3 signatures');
      return;
    }

    this.modalService.open(content, {
      size: 'lg',
      scrollable: true,
      centered: true,
    });
  }

  previewSignature(index: number) {
    this.selectedIndex = index;
  }

  selectSignature(index: number) {
    this.selectedIndex = index;
  }

  handleModalClose(event: boolean) {
  }

  updateSignatures(updatedSignatures: any) {
    console.log("updatedSIngature", updatedSignatures);
    this.signatures.push(updatedSignatures[0]);
  }

  deleteSignature(index: number) {
    this.signatures.splice(index, 1);
  }

  previewSignatureModal(content: any, selectedIndex: number | null) {
    this.selectedIndex = selectedIndex;
    this.modalService.open(content, {
      size: 'lg',
      scrollable: true,
      centered: true,
    });
  }
}



export class User {
  user_id!: Number;
  prefix: string = "";
  fname: string = "";
  lname: string = "";
  email: string = "";
  password!: JSON;
  username: String = "";
  gender: String = "";
  picture: String = "";
  profile: String = "";
  twoFactorAuthentication: String = "";
  status: String = "";
  // client_id:String = "";
  client_id: Number = 0;
  center_id: Number = 0;
  dept_id: Number = 0;
  role_id: Number = 0;
}
