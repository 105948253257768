import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { catchError, debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CompanyService } from 'src/app/private/services/company.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-auto-complete-search',
  templateUrl: './auto-complete-search.component.html',
  styleUrls: ['./auto-complete-search.component.scss']
})
export class AutoCompleteSearchComponent {
  searchControl = new FormControl('');
  @Input() label: string = 'Search Parent Account';
  options: any[] = [];
  page = 0;
  isLoading = false;
  selectedId: any = null;
  @Input() selectedName: any = null;
  @Input() account: any;
  @Input() formAction: string = 'Add';
  @Input() type: any;

  constructor(private http: HttpClient,
    private api: CompanyService,
    private localStorage: LocalStorageService

  ) { }

  ngOnInit() {


    if (this.formAction == 'Update') {
      if (this.type == 'account') {
        console.log("account123", this.account)
        this.searchControl.setValue(this.account.ParentAccount?.account_name)
        this.selectedId = this.account?.parent_account
      } else {
        this.searchControl.setValue(this.account?.account_name)
        this.selectedId = this.account?.account_id
      }
    }

    this.fetchData('').pipe(
      catchError((error) => {
        console.error('Error fetching data:', error);
        this.isLoading = false;
        return of({ rows: [] });
      })
    ).subscribe(data => {
      this.options = data?.rows;
      this.isLoading = false;
    });

    this.searchControl.valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => {
          this.page = 0;
          this.options = [];
        }),
        switchMap(value => (value ? this.fetchData(value) : of([])))
      )
      .subscribe(data => {
        this.options = data?.rows;
      });
  }

  fetchData(query: any) {
    this.isLoading = true;

    let param: any = {
      "page": this.page,
      "limit": 10,
      where: {
        client_id: this.localStorage.getClientId(),
      },
      "order": {
        "col": "",
        "dir": ""
      }
    };

    if (query) {
      param.filter = {
        account_name: query
      };
    }

    return this.api.getAllCompany(param).pipe(
      tap(() => (this.isLoading = false)),
      catchError((error) => {
        this.isLoading = false;
        console.error('Error fetching data:', error); // Optional: Log the error
        return of([]); // Return an empty array or appropriate fallback data
      })
    );
  }


  onScroll(event: Event) {
    const element = event.target as HTMLElement;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight && !this.isLoading) {
      this.page++;
      this.fetchData(this.searchControl.value).subscribe(data => {
        this.options = [...this.options, ...data];
      });
    }
  }

  onSelectionChange(event: any) {
    console.log('Selected:', event);
    this.selectedId = event?.account_id
    this.selectedName = event?.account_name
    console.log('selectedName:', this.selectedName);
  }
}
