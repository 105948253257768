import { Component, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { TicketService } from 'src/app/private/services/ticket.service';
import { UsersService } from 'src/app/private/services/users.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})



export class TicketsComponent {

  users: any = [];
  isLoading = false;
  totalRows = 0;
  pageSize = 25;
  currentPage = 0;
  btnLoading: boolean = false;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  currentTab = 'Tina';
  currentFilterTab = 'allTicket';
  displayedColumns: string[] = ["select", "ticketId",  "title", "description", "status","requestedAt", "updatedAt", "assignedTo", "priority", "action"];
  loading = true;
  formAction: any = "Create";
  selectedTicket: any = {};
  curentUser: any = {};
  closeResult = '';
  ticketArrayData = [];
  hoverIndex: any = -1;
  ticketStatuses: any = [
    {"key":"allTicket","value":"All Tickets"},
    { "key": "New", "value": "New" },
    { "key": "Open", "value": "Open" },
    { "key": "In Progress", "value": "In Progress" },
    { "key": "On Hold", "value": "On Hold" },
    { "key": "Escalated", "value": "Escalated" },
    { "key": "Resolved", "value": "Resolved" },
    { "key": "Closed", "value": "Closed" },
    { "key": "Cancelled", "value": "Cancelled" },
    { "key": "Draft", "value": "Draft" },
    { "key": "Reopened", "value": "Reopened" },
    { "key": "Deleted", "value": "Deleted" },
    ]



  

  defaultFilter:any = [
    'ticket_status',
    'priority',
    'assign_to',
    'title'
  ];



  temp: any = [];
  isAllSelectedCheckBox: boolean = false;
  
  selectedTicketForActivity: any = {};

  constructor(private toast: ToastrService, private api: TicketService, private modalService: NgbModal, private localStorage: LocalStorageService,
    private userApi: UsersService
  ) {

  }



  ngOnInit(): void {
    this.getAllUsers();
    this.getCurrentUserInfo();
  }

  getCurrentUserInfo() {
    let param = {
      user_id: this.localStorage.getUserId(),
    };
    this.userApi.getUser(param).subscribe({
      next: (res: any) => {
        console.log("curentUser", res)
        this.curentUser = res || [];
      },
      error: (e: any) => {
        console.error(e);
      },
    });
  }

  ngAfterViewInit() {

    this.loadTinaData('allTicket');

  }

  getAllUsers() {
    let param = {
      client_id: this.localStorage.getClientId(),
    };
    this.userApi.findAllUsers(param).subscribe({
      next: (res: any) => {
        this.users = res || [];
      },
      error: (e: any) => {
        console.error(e);
      },
    });
  }

  loadTinaData(filterParam: any) {

    
    try {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      this.loading = true;

      merge(
        this.dateQuery.valueChanges,
        this.searchQuery.valueChanges,
        this.sort.sortChange,
        this.paginator.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            this.loading = true;
            // Define the type of `this.param`
            console.log("searchQuery", this.searchQuery.value)
            let param: any = {
              client_id: this.localStorage.getClientId(),
              order: { 
                col: '"manage_ticket"."createdAt"',
                dir: "DESC" 
              },
              "page": this.paginator.pageIndex * this.paginator.pageSize,
              "limit": this.paginator.pageSize,
              // filter:this.searchQuery.value,
              where: {
                client_id: this.localStorage.getClientId(),
                source: this.currentTab,
              },
            };

            if (this.dateQuery.value) {
              param = { ...param, ...this.dateQuery.value };
            }

            if(this.searchQuery.value) {
       
              for (let [key, value] of Object.entries(this.searchQuery.value)) {
               
                if(this.defaultFilter.includes(key)){
                  key = (key == 'assign_to') ? 'assign_to' : key  
                  const obj = {
                    ...param.where,
                    [key] : value,
                  };
                  param.where = obj;
                }
              }
  
            }

            

            // Add status conditionally
            if (this.currentFilterTab !== 'allTicket') {
              param.where.ticket_status = this.currentFilterTab;
            }

            return this.api.getAllTinaTicket(param).pipe(
              catchError(() => observableOf(null)) // Handle errors gracefully
            );
          }),
          map((response: any) => {
            if (!response) {
              this.ticketArrayData = [];
              return []; // Return an empty array if no response
            }
            this.ticketArrayData = response?.rows || [];
            this.totalRows = response.count; // Update total rows
            return response;
          })
        )
        .subscribe({
          next: (data: any) => {
            this.dataSource = new MatTableDataSource<any>(data.rows || []);
            this.loading = false;
          },
          error: (e) => {
            this.loading = false;
            console.error('Error loading Tina data:', e);
          },
          complete: () => {
            this.loading = false;
            console.log('Data loading complete.');
            this.isLoading = false;
          },
        });
    } catch (e) {
      this.isLoading = false;
      console.error('Unexpected error:', e);
    }
  }

  searchFilter(query: any) {
    this.searchQuery.setValue(query);
  }
  ResetDateFilter(event: any) {
    this.dateQuery.setValue("");
  }
  ResetFilter(event: any) {
    this.searchQuery.setValue("");
  }

  loadTinaData2(filterParam?: any) {
    this.temp = [];
    this.isAllSelectedCheckBox = false;
    try {

      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            // Define the type of `this.param`
            this.loading = true;
            let param:any = {
              client_id: this.localStorage.getClientId(),
              order: { 
                col: '"manage_ticket"."createdAt"',
                dir: "DESC" 
              },
              "page": this.paginator.pageIndex * this.paginator.pageSize,
              "limit": this.paginator.pageSize,
              where: {
                client_id: this.localStorage.getClientId(),
                source: this.currentTab,
              },
            };

            if (this.dateQuery.value) {
              param = { ...param, ...this.dateQuery.value };
            }

            if(this.searchQuery.value) {
       
              for (let [key, value] of Object.entries(this.searchQuery.value)) {
               
                if(this.defaultFilter.includes(key)){
                  key = (key == 'assign_to') ? 'user_id' : key  
                  const obj = {
                    ...param.where,
                    [key] : value,
                  };
                  param.where = obj;
                }
              }
  
            }

            // Add status conditionally
            if (this.currentFilterTab !== 'allTicket') {
              param.where.ticket_status = this.currentFilterTab;
            }

            return this.api.getAllTinaTicket(param).pipe(
              catchError(() => observableOf(null)) // Handle errors gracefully
            );
          }),
          map((response: any) => {
            if (!response) {
              this.ticketArrayData = [];
              return []; // Return an empty array if no response
            }
            this.ticketArrayData = response?.rows || [];
            this.totalRows = response.count; // Update total rows
            return response;
          })
        )
        .subscribe({
          next: (data: any) => {
            this.dataSource = new MatTableDataSource<any>(data.rows || []);
            this.loading = false;
          },
          error: (e) => {
            this.loading = false;
            console.error('Error loading Tina data:', e);
          },
          complete: () => {
            this.loading = false;
            console.log('Data loading complete.');
          },
        });
    } catch (e) {
      console.error('Unexpected error:', e);
    }
  }

  changeTab(tab: any) {

    
    this.currentTab = tab;
    this.currentFilterTab = 'allTicket';
    this.totalRows = 0;
    this.currentPage = 0;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;
    this.dataSource = new MatTableDataSource();
    this.loadTinaData2('allTicket');
    this.isAllSelectedCheckBox = false;
    this.temp = [];


  }


  changeFilterTab(filterTab: any, mainTab: any) {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;
    this.currentFilterTab = filterTab;
    this.loadTinaData2(filterTab);
    this.isAllSelectedCheckBox = false;
    this.temp = [];
  }

  editTicket(content: any, object: any) {
    this.formAction = "Update";
    this.selectedTicket = object;
    this.modalService.open(content, { size: 'lg', scrollable: true ,
    centered: true,
    backdrop: 'static',
    })
  }


  open(content: any) {
    // this.formAction = "add";
    // this.getUser();
    // this.clearTicketForm();
    // this.ticket.requested_by_name = this.localStorage.getProfileName();
    // // this.ticket.requested_by_email = this.localStorage.getProfileEmail();
    this.formAction = "Create";
    this.modalService.open(content, {
      size: 'lg', scrollable: true,
      centered: true,
      backdrop: 'static',
      keyboard: false
    }).result.then(
      (result: any) => {
        this.closeResult = `Closed with: ${result}`;

      },
      (reason: any) => {

        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  deleteTicket(obj: any,
    content:any
  ) {
    
    if(this.currentFilterTab === 'Deleted') {
      this.selectedTicket = obj;
     this.modalService.open(content, {
      size: 'lg', scrollable: true,
      centered: true,
      backdrop: 'static',
      keyboard: false
    })
      return;
    }else{
      obj.ticket_status = "Deleted";
    this.loading = true;
    this.api.updateTicket(obj).subscribe({
      next: (c: any) => {
        this.loadTinaData2('allTicket');
      },
      error: (e: any) => {

      },
      complete: () => { }
    })
    }

  }

  handleDeleteEvent() {
    const paylaod = {
      id: this.selectedTicket.id,
    }
    this.btnLoading = true;
    this.api.deleteTicket(paylaod).subscribe({
      next: (c: any) => {
        this.loadTinaData2('allTicket');
        this.modalService.dismissAll();
      },
      error: (e: any) => {

      },
      complete: () => {
        this.btnLoading = false;
       }
    })
  }

  selectAll(event: any) {

    this.temp = [];

    var checkboxes: any = document.getElementsByClassName('check_input_manual');
    if (event.target.checked) {
      this.isAllSelectedCheckBox = true;
      for (var i = 0; i < checkboxes.length; i++) {

        console.log(checkboxes[i].type)
        console.log(this.ticketArrayData)

        if (checkboxes[i].type == 'checkbox') {
          // console.log(this.ticketArrayData)
          let ticketObject = this.ticketArrayData.find((ticket: any) => ticket.ticket_id == checkboxes[i].value);
          // console.log(ticketObject)
          checkboxes[i].checked = true;
          this.temp.push(ticketObject)
        }
      }
    }
    else {
      this.isAllSelectedCheckBox = false;
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox') {
          checkboxes[i].checked = false;
        }
      }
      this.temp = [];
    }

  }

  openTicketList(content: any) {
    if (this.temp.length < 1) {
      this.toast.error("Please select a record to create the list !");
    }
    else {

      this.formAction = "Update Bulk";
      this.modalService.open(content, { size: 'lg', scrollable: true,
      centered: true,
      backdrop: 'static',
      keyboard: false
       }).result.then(
        (result: any) => {
          this.closeResult = `Closed with: ${result}`;

        },
        (reason: any) => {

          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
    }
  }

  openActivity(content: any, ticket: any) {
    this.selectedTicketForActivity = ticket;

    console.log("selectedTicketForActivity", this.selectedTicketForActivity)
    // this.getTicketHistory(ticketId);
    this.modalService.open(content, { size: 'lg', scrollable: true,
    centered: true,
    backdrop: 'static',
    keyboard: false
     }).result.then(
      (result: any) => {
        this.closeResult = `Closed with: ${result}`;

      },
      (reason: any) => {

        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  showDescription(content: any, ticket: any) {
    this.selectedTicketForActivity = ticket;

    console.log("selectedTicketForActivity", this.selectedTicketForActivity)
    this.modalService.open(content, { size: 'lg', scrollable: true,
    centered: true
     })
  }

 
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

    selectOne(event: any, element: any) {
    if (event.target.checked) {
      this.temp.push(this.ticketArrayData.find((ticket: any) => ticket.ticket_id == element.ticket_id));
    }
    else {
      this.temp = this.temp.filter(function (obj: any) {
        return obj.ticket_id !== element.ticket_id;
      })
    }

  }

    dateFilter(event: any) {
    this.dateQuery.setValue(event);
  }

  isImage(file: string): boolean {
    return /\.(jpg|jpeg|png|gif|webp)$/i.test(file);
  }

  // Check if the file is a video
  isVideo(file: string): boolean {
    return /\.(mp4|webm|ogg|mov)$/i.test(file);
  }

  // Get file name from the URL (optional, for display)
  getFileName(file: string): string {
    return file.split('/').pop() || 'Unknown File';
  }

  
  isPDF(file: string): boolean {
    return /\.(pdf)$/i.test(file);
  }

































  // file: any;
  // url: any = 'assets/img/no-image.jpg';
  // isChecked = false;

  // userDropdown: any = [];
  // ticketHistoryList: any = [];
  // ticketStatus: any = "";


  // isSubmitted: boolean = false;

  // exportColumns: string[] = ["ticket_id", "hospital_id", "status", "ticket_body", "ticket_subject", "priority", "source", "assign_to", "requested_by", "tag", "type", "RequestedOn", "UpdatedOn"];
  // dateObject: any = { startDate: "", endDate: "" }
  // ticket: Ticket = new Ticket();
  // @ViewChild("leadForm")
  // leadForm!: NgForm;
  // @ViewChild("ticketForm")
  // ticketForm!: NgForm;
  // @ViewChild("leadFolloupForm")
  // leadFolloupForm!: NgForm;
  // leadsFollowList: any = [];
  // leadChannels: any = [];
  // exportArrayData = [];
  // param = {};




  
  // clearTicketForm() {
  //   this.ticket = new Ticket();
  // }
  // // load Initial table data from based on source type


  // loadZendeskData(filterParam: any) {
  //   try {
  //     this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
  //     merge(this.dateQuery.valueChanges, this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
  //       switchMap(() => {
  //         // console.log(this.dateQuery.value)
  //         let param = {
  //           where: { hospital_id: this.localStorage.getHospitalId() },
  //           filter: this.searchQuery.value,
  //           page: (this.paginator.pageIndex * this.paginator.pageSize),
  //           limit: this.paginator.pageSize,
  //           // startDate:this.dateQuery.value.startDate,
  //           // endDate:this.dateQuery.value.endDate,
  //           order: [{ col: 'createdAt', dir: "desc" }],
  //         }
  //         if (this.dateQuery.value) { param = { ...param, ...this.dateQuery.value } }

  //         return this.api.getAllZendeskTicket(param)
  //           .pipe(catchError(() => observableOf(null)));
  //       }), map((response: any) => {
  //         if (response === null) {
  //           return [];
  //         }

  //         this.totalRows = response.count;
  //         return response
  //       })
  //     ).subscribe({
  //       next: (data: any) => {
  //         // console.log(data)
  //         this.exportArrayData = data.rows;
  //         if (filterParam === 'allTicket') {
  //           this.dataSource = new MatTableDataSource<any>(data.rows);
  //         }
  //         if (filterParam === 'pending') {
  //           let filteredTickets = data.rows.filter((data: any) => {
  //             return data.status === 'pending';
  //           });
  //           this.dataSource = new MatTableDataSource<any>(filteredTickets);
  //         }
  //         if (filterParam === 'unassigned') {
  //           let filteredTickets = data.rows.filter((data: any) => {
  //             return data.assign_to === null;
  //           });
  //           this.dataSource = new MatTableDataSource<any>(filteredTickets);
  //         }
  //         if (filterParam === 'open') {
  //           let filteredTickets = data.rows.filter((data: any) => {
  //             return data.status === 'open';
  //           });
  //           this.dataSource = new MatTableDataSource<any>(filteredTickets);
  //         }
  //         if (filterParam === 'solved') {
  //           let filteredTickets = data.rows.filter((data: any) => {
  //             return data.status === 'solved';
  //           });
  //           this.dataSource = new MatTableDataSource<any>(filteredTickets);
  //         }
  //         if (filterParam === 'deleted') {
  //           let filteredTickets = data.rows.filter((data: any) => {
  //             return data.status === 'deleted';
  //           });
  //           this.dataSource = new MatTableDataSource<any>(filteredTickets);
  //         }
  //       },
  //       error: (e) => {
  //         console.error(e)
  //       },
  //       complete: () => {

  //       }
  //     })
  //   }
  //   catch (e) {
  //     console.error(e)
  //   }
  // }






  // getUser() {
  //   let param = {
  //     where: { status: "Active", hospital_id: this.localStorage.getHospitalId() }
  //   }
  //   this.api.getUser(param).subscribe({
  //     next: (res: any) => {
  //       this.userDropdown = res || [];
  //     },
  //     error(err: any) {

  //     },
  //   })
  // }

  // getTicketHistory(ticketId: any) {
  //   let param = {
  //     where: { ticket_id: ticketId }
  //   }
  //   this.api.getTinaTicketHistory(param).subscribe({
  //     next: (res: any) => {
  //       this.ticketHistoryList = res.rows || [];
  //     },
  //     error(err: any) {

  //     },
  //   })
  // }

  // isAllSelected() {
  //   return this.dataSource.data.every((item: any) => item.isSelected);
  // }

  // isAnySelected() {
  //   return this.dataSource.data.some((item: any) => item.isSelected);
  // }



  






 
  // uploadImage(ticket_id: any, fileName: any) {

  //   // if (this.file) {
  //   //   console.log(this.file)
  //   //   const formData = new FormData();
  //   //   formData.append("file", this.file, fileName);
  //   //   formData.append("folders", "public/TicketImages/");
  //   //   formData.append("bucket", "emruploads");
  //   //   // console.log(formData)
  //   //   this.uploadService.uploadPatientProfile(formData).subscribe({
  //   //     next: (res:any) => {
  //   //       if (res) {
  //   //         let param = {
  //   //           ticket_id: ticket_id,
  //   //           attachement_link: res.url,
  //   //           remarks: "Attachment uploded"

  //   //         }
  //   //         this.api.updateTicket(param).subscribe({
  //   //           next: (res: any) => {
  //   //           }
  //   //         })

  //   //       }
  //   //     },
  //   //     error: (e:any) => {
  //   //       console.log(e);
  //   //     }
  //   //   })

  //   // }
  // }

  // onFileSelected(event: any) {

  //   const files = event.target.files;
  //   if (files.length === 0)
  //     return;

  //   const mimeType = files[0].type;
  //   if (mimeType.match(/image\/*/) == null) {
  //     this.toast.error('Only images are supported.');
  //     return;
  //   }

  //   const reader = new FileReader();
  //   this.file = files[0];
  //   reader.readAsDataURL(files[0]);
  //   reader.onload = (_event) => {

  //     // this.patient.photo = reader.result; 
  //   }

  // }


  // SaveTicket(isValid: any) {
  //   this.isSubmitted = true;

  //   if (isValid)
  //     console.log();
  //   {
  //     if (this.formAction == 'add') {
  //       this.btnLoading = true;
  //       this.ticket.hospital_id = this.localStorage.getHospitalId();
  //       this.ticket.source = "tina";
  //       if (this.ticket.assign_to && Object.keys(this.ticket.assign_to).length !== 0) {
  //         // console.log("this.ticket.assign_to.length", this.ticket.assign_to)
  //         this.ticket.assign_to_id = this.ticket.assign_to.user_id;
  //         this.ticket.assign_to_name = this.ticket.assign_to.prefix + " " + this.ticket.assign_to.fname + " " + this.ticket.assign_to.lname;
  //       }
  //       this.api.addTicketData(this.ticket).subscribe({
  //         next: (res: any) => {
  //           this.modalService.dismissAll()
  //           this.ticket = new Ticket();
  //           if (res) {
  //             this.uploadImage(res.ticket_id, res.ticket_id + res.createdAt);
  //           }
  //           this.loadTinaData("allTicket");
  //           this.btnLoading = false;
  //           this.toast.success("Ticket Added Successfully", "Success!!")
  //         },
  //         error: (e: any) => {
  //           this.btnLoading = false;
  //         },
  //         complete: () => { }
  //       })
  //     }
  //     else if (this.formAction == 'update') {
  //       //alert()
  //       if (this.ticket.assign_to && Object.keys(this.ticket.assign_to).length !== 0) {
  //         // console.log("this.ticket.assign_to.length", this.ticket.assign_to)
  //         this.ticket.assign_to_id = this.ticket.assign_to.user_id;
  //         this.ticket.assign_to_name = this.ticket.assign_to.prefix + " " + this.ticket.assign_to.fname + " " + this.ticket.assign_to.lname;
  //       }
  //       this.btnLoading = true;
  //       this.api.updateTicket(this.ticket).subscribe({
  //         next: (c: any) => {
  //           this.modalService.dismissAll()
  //           this.loadTinaData('allTicket')
  //           this.isSubmitted = false
  //           this.btnLoading = false;
  //           this.toast.success("Ticket Updated Successfully", "Success!!");
  //           this.updateTicketHistory(this.ticket);
  //           this.ticket = new Ticket();

  //         },
  //         error: (e: any) => {
  //           this.btnLoading = false;
  //           this.isSubmitted = false
  //         },
  //         complete: () => { }
  //       })

  //     }
  //   }
  // }

  // updateTicketHistory(ticket: any) {
  //   this.ticket.updatedBy = {
  //     userId: this.localStorage.getUserId(),
  //     name: this.localStorage.getProfileName(),
  //     // email: this.localStorage.getProfileEmail()
  //   }
  //   this.api.updateTicketHistory(ticket).subscribe({
  //     next: (c: any) => {
  //     },
  //     error: (e: any) => {
  //       this.btnLoading = false;
  //       this.isSubmitted = false
  //     },
  //     complete: () => { }
  //   })
  // }
  // createBulkTicketHistory(ticket: any) {
  //   // console.log("updateBulkTicketHistory", ticket);
  //   this.api.createBulkTicketHistory(ticket).subscribe({
  //     next: (c: any) => {
  //     },
  //     error: (e: any) => {
  //       this.btnLoading = false;
  //       this.isSubmitted = false
  //     },
  //     complete: () => { }
  //   })
  // }


  // export(header: any) {

  //   // let excelRowData: any = [];
  //   // this.exportArrayData.forEach((element: any) => {
  //   //   excelRowData.push({ 0: element.ticket_id, 1: element.hospital_id, 2: element.status, 3: element.ticket_body, 4: element.ticket_subject, 5: element.priority, 6: element.source, 7: element.assign_to_name, 8: element.requested_by_email, 9: element.tag, 10: element.type, 11: element.createdAt, 12: element.updatedAt, 13: element.requested_by_name })
  //   // });

  //   // let excelData = [];
  //   // let excelHeader = [];
  //   // for (let i = 0; i < excelRowData.length; i++) {
  //   //   let element = excelRowData[i];
  //   //   let obj: any = {}
  //   //   for (let index = 0; index < header.length; index++) {
  //   //     let key = header[index]['key'];
  //   //     obj[key] = element[key]
  //   //   }
  //   //   excelData.push(obj)
  //   // }
  //   // for (let index = 0; index < header.length; index++) {
  //   //   let value = header[index]['value'];
  //   //   excelHeader.push(value)
  //   // }
  //   // // console.log(excelData)
  //   // const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  //   // XLSX.utils.sheet_add_aoa(ws, [excelHeader]);
  //   // XLSX.utils.sheet_add_json(ws, excelData, { origin: 'A2', skipHeader: true });
  //   // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   // let date = new Date();
  //   // let day = date.getDate();
  //   // let month = date.getMonth() + 1;
  //   // let year = date.getFullYear();

  //   // XLSX.writeFile(wb, "ticket_data_" + day + "_" + month + "_" + year + "_" + date.getTime() + ".csv");


  // }


}
function observableOf(arg0: null): any {

  //throw new Error('Function not implemented.');
}
export class Ticket {
  source: string = "";
  hospital_id!: Number;
  ticket_id!: Number;
  requested_by_name: string = "";
  requested_by_email: string = "";
  mobile: string = "";
  assign_to_id!: Number;
  assign_to_name: string = "";
  ticket_subject: string = "";
  ticket_body: string = "";
  status: string = "Open";
  tag: string = "";
  type: string = "";
  priority: string = "";
  attachment: string = "";
  remarks: string = "";
  updatedBy: any = {};
  assign_to: any = "";
  ticketStatus: any = "";
}

