import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-preview-signature-model',
  templateUrl: './preview-signature-model.component.html',
  styleUrls: ['./preview-signature-model.component.scss']
})
export class PreviewSignatureModelComponent {
  @Input() signatures: string[] = [];
  @Input() selectedIndex: number | null = null;
  @Output() close = new EventEmitter<boolean>();
  closeModal() {
    this.close.emit();
  }
}
