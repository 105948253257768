import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'TINA';

  ngOnInit() {
    if (environment.production) {
      this.disableRightClick();
      // this.preventScreenshot();
    }
  }

  disableRightClick() {
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
  }

  preventScreenshot() {
    // Block common screenshot shortcuts
    document.addEventListener('keydown', (event) => {
      const screenshotKeys = ['PrintScreen', 'Meta', 'Shift', 'Control'];
      if (screenshotKeys.includes(event.key)) {
        event.preventDefault();
      }
    });

    // Attempt to clear clipboard data (for some screen capture methods)
    document.addEventListener('keyup', (event) => {
      if (event.key === 'PrintScreen') {
        navigator.clipboard.writeText('');
      }
    });
  }

  
}
