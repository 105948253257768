import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/private/services/company.service';
import { LeadService } from 'src/app/private/services/lead.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-convert-to-account',
  templateUrl: './convert-to-account.component.html',
  styleUrls: ['./convert-to-account.component.scss']
})
export class ConvertToAccountComponent {
  @Input() leadToConvert: any;
  btnLoading = false;
  @Output() reload = new EventEmitter();

  constructor(
    private toast: ToastrService,
    private api: CompanyService,
    private localStorage: LocalStorageService,
    private api2: LeadService,

  ) { }
  @Output() close = new EventEmitter();

  closeModal() {
    this.close.emit();
  }

  convertToAccount() {
    this.btnLoading = true;


    const updateParams = {
      ...this.leadToConvert,
      lead_status: 'Converted',
      convert_to_account: true
    };

    // Call the second API
    this.api2.updateLead(updateParams).subscribe({
      next: () => {
        this.reload.emit();

        setTimeout(() => {
          this.toast.success('Event created successfully');
          this.closeModal();
        }, 1000);

      },
      error: (err) => {
        console.error('Update Lead Error:', err);
        this.toast.error('Failed to update lead');
      },
      complete: () => {
        this.btnLoading = false;
      }
    });

}


}
