import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
  })
  export class LocalStorageService {
    isLogin = false;
    isCenter = false;
    clientLogo:any;
    role:any;
    profile_name:any;
    userId:any
    hospital_id:any;
    center_id:any;
    client_id:any;
    dashboard:any;
    specialty:any;
    permissions:any;
    appointment_config:any
    leadChannels:any;
    feature_modules:any;
    is_fixed_col:any;
    get_location:any;
    woocomerce_cred:any;

    constructor( private router: Router) { }
  
    login(resp: any) {
    //  console.log(resp)
      this.isLogin = true;
      this.role =  resp.role.role_name;
      localStorage.setItem("leadChannels", resp.client?.leadChannels || null);
      localStorage.setItem("appointment_config", resp.appointment_config  || null);
      localStorage.setItem("isproblemArea", resp.isproblemArea  || null);
      localStorage.setItem('center_id', resp.center_id);
      localStorage.setItem('dept_id', resp.dept_id);
      // localStorage.setItem('client_id', resp.client_id);
      localStorage.setItem('role_id', resp.role_id);
      localStorage.setItem('client_id', resp.client_id);
      localStorage.setItem('is_cron',resp.client.isCron)
      localStorage.setItem('user_id', resp.user_id);
      localStorage.setItem('profile_name', resp.fname+" "+resp.lname);
      localStorage.setItem('role', resp.role.role_name);
      localStorage.setItem('clientLogo', resp.client.logo);
      localStorage.setItem('feature_modules', resp.client.feature_modules);
      localStorage.setItem('specialty', resp.client.specialty);
      localStorage.setItem('dateFormat', resp.client.dateFormat);
      localStorage.setItem('permissions', JSON.stringify(resp.role.permissions));
      localStorage.setItem('enablePrintLayout', resp.client.enablePrintLayout);
      localStorage.setItem('emailId', resp.email);
      localStorage.setItem('isCenter', resp.client.is_center);
      localStorage.setItem('is_fixed_col', resp.client.is_fixed_col);
      localStorage.setItem('get_location', resp.client.get_location);
      localStorage.setItem('user_sign', JSON.stringify(resp.user_sign));
      localStorage.setItem('woocomerce_cred', JSON.stringify(resp.client.woocomerce_cred));
      localStorage.setItem('currency', resp.client?.other_settings?.currency);
      let username = resp.username.replace(/([A-Z])/g, ' $1').trim();

      localStorage.setItem('username',username);
      if(resp.client.logo)
      {
        this.toDataUrl(resp.client.logo,this.getBase64Logo)
      }
      
      if(resp.client.enablePrintLayout)
      {
        this.toDataUrl(resp.client.printlayout.pre_header_image,this.getBase64HeaderPrescription)
        this.toDataUrl(resp.client.printlayout.pre_footer_image,this.getBase64FooterPrescription)
      }
      return { success: this.isLogin, role: this.role };
    }
  
    logout() {
      this.isLogin = false;
      this.role = '';
      localStorage.setItem("leadChannels","");
      localStorage.setItem("appointment_config", "");
      localStorage.setItem("isproblemArea", "");
      localStorage.setItem('center_id',"");
      localStorage.setItem('dept_id', "");
      localStorage.setItem('client_id', "");
      localStorage.setItem('role_id', "");
      localStorage.setItem('client_Id', "");
      localStorage.setItem('user_id', "");
      localStorage.setItem('profile_name', "");
      localStorage.setItem('role', "");
      localStorage.setItem('permissions', "");
      localStorage.setItem('specialty', "");
      localStorage.setItem('token', "");
      localStorage.setItem('username',"");
      localStorage.setItem('enablePrintLayout',"");
      localStorage.setItem('getBase64HeaderPrescription',"");
      localStorage.setItem('getBase64FooterPrescription',"");
      localStorage.setItem('getBase64logo',"");
      localStorage.setItem('clientLogo', "");
      localStorage.setItem('feature_modules', "");
      localStorage.setItem('isCenter', "");
      localStorage.setItem('is_fixed_col', "");
      localStorage.setItem('get_location', "");
      localStorage.setItem('woocomerce_cred', "");
      localStorage.setItem('user_sign', "");
  
      return { success: this.isLogin, role: '' };
    }

    getWoocomerceCred() {
      this.woocomerce_cred = localStorage.getItem('woocomerce_cred') || {};
      return this.woocomerce_cred;
    }
    getIsGetLocation() {
      this.get_location = localStorage.getItem('get_location') == 'true' ? true : false ;
      return this.get_location;
    }
    getIsFixedCol() {
      this.is_fixed_col = localStorage.getItem('is_fixed_col') == 'true' ? true : false ;
      return this.is_fixed_col;
    }
    getClientLogo() {
      return localStorage.getItem('clientLogo');
    }
  
    getCron() {
      return localStorage.getItem('is_cron');
    }
    getIsCenter() {
      if (localStorage.getItem('isCenter') == 'True')
        this.isCenter = true;
      else
        this.isCenter = false;
      return this.isCenter;
    }
    getEmail() {
      return localStorage.getItem('emailId');
    }
    isLoggedIn() {
      const loggedIn = localStorage.getItem('isLogin');
      if (loggedIn == 'true')
        this.isLogin = true;
      else
        this.isLogin = false;
      return this.isLogin;
    }
    
    getLeadChannels() {
      this.leadChannels = localStorage.getItem('leadChannels');
      return this.leadChannels;
    }
    getFeatureModules()
    {
      this.feature_modules = localStorage.getItem('feature_modules');
      return this.feature_modules;
    }
    getRole() {
      this.role = localStorage.getItem('role');
      return this.role;
    }
    getSpecialty() {
      this.specialty = localStorage.getItem('specialty');
      return this.specialty;
    }
    getPermissions() {
      this.permissions = localStorage.getItem('permissions');
      return this.permissions;
    }
    getHospitalId() {
      this.hospital_id = localStorage.getItem('hospital_id');
      return this.hospital_id;
    }
    getCenterId() {
      this.center_id = localStorage.getItem('center_id');
      return this.center_id;
    }
    getClientId() {
      this.client_id = localStorage.getItem('client_id');
      return this.client_id;
    }
    getSignatures() {
      let singatures = localStorage.getItem('user_sign');
      return JSON.parse(singatures || '[]');
    }

    getProfileName() {
      this.profile_name = localStorage.getItem('profile_name');
      return this.profile_name;
    }

    getUserId() {
      this.userId = localStorage.getItem('user_id');
      return this.userId;
    }

    getAppointment_config()
    {
      this.appointment_config = localStorage.getItem('appointment_config');
      return this.appointment_config;
    }
    getDashboard() {
        this.dashboard = localStorage.getItem("dashboards")?.split(',');
        return this.dashboard;
      }
    
    getDashboardAccess(dashboardName:any)
    {
        let dashboard:any  =   localStorage.getItem("dashboards")?.split(',');
        console.log(dashboardName+"--"+dashboard)
        let isAuthenticated = false;
        if(dashboard)
        {
            return isAuthenticated =  dashboard.includes(dashboardName)
        }
      

    }
    getBase64HeaderPrescription(data:any){
      localStorage.setItem('getBase64HeaderPrescription', data)
    }
    getBase64FooterPrescription(data:any){
      localStorage.setItem('getBase64FooterPrescription', data)
    }
    getBase64Logo(data:any){
      localStorage.setItem('getBase64logo',data);
    }

    getClientCurrency()
    { 
      console.log(localStorage.getItem('currency'))
      return localStorage.getItem('currency');
    }


    toDataUrl(url:any, callback:any) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
              callback(reader.result);
          }
          reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
  }

  checkFeatures(module:any)
  {
   let features =  JSON.parse(JSON.stringify(this.feature_modules));
   return (features.includes(module) ? true : false);
  }

  checkCRUDAccess(module:any,submodule:any)
  {
    let accessObj = {
      "title": "",
      "name": "",
      "active": false,
      "update": false,
      "export": false,
      "import": false,
      "create": false,
      "delete": false
  }
   let features  = JSON.parse(this.getPermissions()).find((item:any)=>{
      return  item.module_name  == module;
  })
  if(features) {
    accessObj = {...accessObj,...features['features'].find((item:any)=>{
      return  item.name  == submodule;
    })}
  }

   return accessObj;
  }

  checkUserAccess(module_name:any,feature_name:any)
  {
        //console.log(this.permissions)
        let feature  = [];
        let module = [];
        module = this.permissions.filter((item:any)=>{
        return  item.module_name  == module_name;
        })
        if(module.length > 0)
        {
        feature = module[0]['features'].filter((item:any)=>{
          return  item.name  == feature_name;
          })
        }

        return (module[0] && feature[0] ) ? true : false;
  }

  checkFullAccessForTableList(role:any)
  {
   const roles = [
    'Administrator',
    'Superadmin',
    'Admin',
    'admin',
    'administrator',
    'Receptionist',
    'receptionist',
    'reception',
    'Reception',
   ]
   return roles.includes(role);
  }

  
  }
