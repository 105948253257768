import { Component, Pipe, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { PipeLineService } from 'src/app/private/services/pipeline.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { of as observableOf } from 'rxjs';

@Component({
  selector: 'app-pipeline',
  templateUrl: './pipeline.component.html',
  styleUrls: ['./pipeline.component.scss']
})
export class PipelineComponent {
  searchQuery = new FormControl();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;

  dateQuery = new FormControl();
  center_id: any;
  totalRows = 0;
  temp: any = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  updateData: any;
  loading = true;
  reloading = false;
  btnLoading = false;

  displayedColumns = ['pipelineName', 'stageName', 'action'];
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  deleteData: any;


   constructor(
      private localStorage: LocalStorageService,
      private api: PipeLineService,
      // private userApi: UsersService,
      private modalService: NgbModal,
      private toast: ToastrService,
  
    ) { }

    ngAfterViewInit() {
      this.loadData();
    }

  formAction = 'Create';

  searchFilter(query: any) {
    this.searchQuery.setValue(query);
  }

  filterArray: any = [];

  open(content: any) {
    this.formAction = 'Add';
   
    this.modalService
      .open(content, { size: 'lg', scrollable: true,
        centered: true, backdrop: 'static', keyboard: false 
       })
  }

   loadData() {
      console.log('loadData');
      try {
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        console.log('this.paginator', this.paginator);
        merge(
          this.searchQuery.valueChanges,
          this.sort.sortChange,
          this.paginator.page
        )
          .pipe(
            startWith({}),
            switchMap(() => {
              let param:any = {
               
                  client_id: this.localStorage.getClientId(),
                
                // page: this.paginator.pageIndex * this.paginator.pageSize,
                // limit: this.paginator.pageSize,
              };
  
              if (this.searchQuery.value) {
                param.filter = this.searchQuery.value;
              }
  
              return this.api
                .getAllPipeLine(param)
                .pipe(catchError(() => observableOf(null)));
            }),
            map((response: any) => {
              if (response === null) {
                this.loading = false;
                return [];
              }
              this.totalRows = response.count;
              return response|| [];
            })
          )
          .subscribe({
            next: (data) => {
              console.log('data', data);
              this.loading = false;
              this.dataSource = new MatTableDataSource<any>(data);
            },
            error: (e) => {
              console.error(e);
              this.loading = false;
            },
            complete: () => {
              this.loading = false;
            },
          });
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    }

    reloadData() {
      this.reloading = true;
      try {
        merge(
        )
          .pipe(
            startWith({}),
            switchMap(() => {
              let param:any = {
               
                  client_id: this.localStorage.getClientId(),
                
                // page: this.paginator.pageIndex * this.paginator.pageSize,
                // limit: this.paginator.pageSize,
              };
  
              if (this.searchQuery.value) {
                param.filter = this.searchQuery.value;
              }
  
              return this.api
                .getAllPipeLine(param)
                .pipe(catchError(() => observableOf(null)));
            }),
            map((response: any) => {
              if (response === null) {
                this.loading = false;
                this.reloading = false;
                return [];
              }
              this.totalRows = response.count;
              return response|| [];
            })
          )
          .subscribe({
            next: (data) => {
              console.log('data', data);
              this.loading = false;
              this.reloading = false;
              this.dataSource = new MatTableDataSource<any>(data);
            },
            error: (e) => {
              console.error(e);
              this.loading = false;
              this.reloading = false;
            },
            complete: () => {
              this.loading = false;
              this.reloading = false;
            },
          });
      } catch (e) {
        console.error(e);
        this.loading = false;
        this.reloading = false;
      }
    }

  openEdit(content: any, data: any) {
    this.formAction = 'Update';
    this.updateData = data;
    this.modalService.open(content, { size: 'lg', scrollable: true,
    centered: true, backdrop: 'static', keyboard: false });
  }

  openDeleteEvent(content: any, data: any) {
    this.deleteData = data;
    this.modalService.open(content, { size: 'lg', scrollable: true,
    centered: true, backdrop: 'static', keyboard: false });
  }

  handleDeletePipeline() {
    
    const parmas = {
      "pipeline_id": this.deleteData.pipeline_id,
    }
    this.btnLoading = true;
    this.api.deletePipeLine(parmas).subscribe({
      next: (res: any) => {
        console.log('Delete Pipeline Response:', res);
        this.toast.success('Pipeline deleted successfully');
        this.modalService.dismissAll();
        this.reloadData();
      },
      error: (err: any) => {
        console.error('Delete Pipeline Error:', err);
        this.toast.error('Failed to delete pipeline');
        this.btnLoading = false;
      }
    });
  }

  getStageNames(stages: any[]): string {
    return stages?.map(stage => stage.stage_name).join(', ') || '';
  }
  

}
