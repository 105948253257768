import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-kanban-board',
  templateUrl: './kanban-board.component.html',
  styleUrls: ['./kanban-board.component.scss']
})
export class KanbanBoardComponent {
 
@Input() status:any = [];
showStatus :any = [];
@Input() dataset:any = [];
@Input() headerColumn:any = [];
@Output('updateStatus') updateStatus: EventEmitter<any> = new EventEmitter();
boardFields = ['lead_id','company_name','lead_type','lead_source','createdAt']
// status = ['To Do','In Progress','Done']
//   dataset = [
//     { id:1,title: 'Task 1' ,status:'In Progress'},
//     {id:2, title: 'task 2',status:'In Progress'},
//     { id:3,title: 'Task 3',status:'To Do'},
//     { id:4,title: 'Task 4',status:'Done'}
//   ];

constructor(){
  console.log(this.dataset)
}
ngOnInit(){
  this.status.forEach((element:any) => {
    this.showStatus.push(element.value)
  });
}
  

  public dropGrid(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.status, event.previousIndex, event.currentIndex);
    console.log(event)
  }
  public drop(event: CdkDragDrop<any>,status:any): void {
 
    if (event.previousContainer === event.container) {
   
      moveItemInArray(this.dataset, event.previousIndex, event.currentIndex);
    } else {
     
      console.log(event.previousContainer.data)
      console.log(event.container.data)
      transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
          const movedTicket = event.container.data[event.currentIndex];
         // alert(movedTicket)
      movedTicket[this.headerColumn] = status;  // Set the new status for the moved ticket
      console.log(movedTicket)
      this.updateStatus.emit(movedTicket)
    }
  }
  getStatusWiseTask(status:any){

    return this.dataset.filter((item:any)=> { return item[this.headerColumn] == status})
  }
}
