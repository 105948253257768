import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { FileRepository, ImageResize } from 'ckeditor5';
import { ToastrService } from 'ngx-toastr';
import { catchError, of, switchMap } from 'rxjs';
import { EmailHubService } from 'src/app/private/services/email-hub.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';



@Component({
  selector: 'app-mail-right-section',
  templateUrl: './mail-right-section.component.html',
  styleUrls: ['./mail-right-section.component.scss']
})
export class MailRightSectionComponent {
  @Input() selectedRow!: any;
  @Input() fetchingDraftData = false;
  @Input() sanitizedContent: SafeHtml | null = null;
  @Input() isComposeOpen = false;
  @Output() refresh = new EventEmitter<any>();
  @Input() loadingEmailData = false;
  @Input() activeAction: string = 'inbox';
  @Input() isDraft = false;
  @Input() currentCreatedDraftId: any;

  @Output() closeCompose = new EventEmitter<any>();
  @Output() closeCompose2 = new EventEmitter<any>();
  @Output() makeIsDraftFalse = new EventEmitter<any>();

  @ViewChild('messageContainer', { static: false }) messageContainer!: ElementRef;

  openBCC = false;
  openCC = false;
  public Editor = ClassicEditor as unknown as any;
  editorData: string = ''; // CKEditor data
  files: any[] = [];
  to: string = '';
  cc: string = '';
  bcc: string = '';
  subject: string = '';
  btnLoading = false;
  emailInput: string = '';
  emails: string[] = [];
  ccInput: string = '';  // Holds the current input value for CC
  ccEmails: string[] = []; // Array to store CC emails as chips
  draft_message_id: any;
  isReply = false;
  isReplyAll = false;
  isForward = false;
  hoverIndex: any = -1;
  hoverIndex2: any = -1;
  replyId: any;
  attachments: any[] = [];
  selectedAttachment: any;
  ckconfig: any;
  currentSingature: string = '';

 editorConfig = {
  
    enterMode: 'SHIFT+ENTER',
    extraPlugins: [],  // Add Image and ImageResize plugins
    imageResizeOptions: [
      { name: 'resizeImage:original', value: null, icon: 'original' },
      { name: 'resizeImage:50', value: '50', icon: '50' },
      { name: 'resizeImage:75', value: '75', icon: '75' },
      { name: 'resizeImage:100', value: '100', icon: '100' }
    ]
  };

  


  constructor(
    private toast: ToastrService,
    private api: EmailHubService,
    private localStorage: LocalStorageService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal
  ) { 
   
  }



  TheUploadAdapterPlugin(editor: any): void {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return new class {
        constructor(private loader: any) {}

        // Upload logic
        upload(): Promise<any> {
          return this.loader.file
            .then((file: File) =>
              new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append('upload', file);

                const xhr = new XMLHttpRequest();
                xhr.open('POST', 'https://your-server.com/upload', true); // Replace with your server's endpoint
                xhr.responseType = 'json';

                xhr.onload = () => {
                  if (xhr.status === 200) {
                    // Resolve with the uploaded image URL
                    resolve({
                      default: xhr.response.url, // Ensure the server responds with the image URL
                    });
                  } else {
                    reject(`Upload failed with status ${xhr.status}`);
                  }
                };

                xhr.onerror = () => {
                  reject('An error occurred during the upload process.');
                };

                xhr.send(formData);
              })
            );
        }

        // Abort logic
        abort(): void {
          // Add abort functionality if necessary
        }
      }(loader);
    };

  }

  ngOnInit() {

    

    ClassicEditor
      .create(document.querySelector('#editor') as HTMLElement, {
        toolbar: ['imageResize', 'imageStyle:full', 'imageStyle:side'],
        image: {
          toolbar: ['imageTextAlternative', 'imageResize', 'imageStyle:full', 'imageStyle:side'],
        },
      })
      .catch(error => {
        console.error(error);
      });

    
      let signature = this.localStorage.getSignatures();
       signature.forEach((element: any) => {
        if(element.isActive === true || element.isActive === 'true'){
          this.currentSingature = element.signature;
        }
      });

      console.log('Current Signature:', this.currentSingature);
      
     
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedRow'] && !changes['selectedRow'].firstChange) {
      // Reset flags to false if selectedRow changes

      if (this.isReply || this.isReplyAll || this.isForward) {
        this.handleUpdateDraft();
      }

      this.isReply = false;
      this.isReplyAll = false;
      this.isForward = false;
      this.replyId = null;
      this.clearData();
    }

    console.log("this draft",this.isDraft);

    if(changes['isComposeOpen']  && !changes['isComposeOpen'].firstChange){
      if(this.isComposeOpen && this.fetchingDraftData === false && 
        this.currentSingature
      ){
        this.editorData = `<br><br><br><br>${this.currentSingature}`
      }
    }
  }


  selectAction(action: string): void {
    // this.bottomSheetRef.dismiss(action);
  }

  openLink(event: MouseEvent): void {
    // this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  handleOpenBCC() {
    this.openBCC = !this.openBCC;
  }

  handleOpenCC() {
    this.openCC = !this.openCC;
  }

  onFileSelected(event: any) {
    const selectedFiles: FileList = event.target.files;
    console.log('Selected Files:', selectedFiles);
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      if (file.size > 4 * 1024 * 1024) {
        // Show error toast
        this.toast.error('File size cannot be more than 4MB');
        continue; // Skip this file
      }
      const reader = new FileReader();
      console.log('File:', file);
      reader.onload = (e: any) => {
        this.files.push({ file, preview: e.target.result });
        console.log('Files:', this.files);
      };

      reader.readAsDataURL(file);  // Generate preview
    }
  }

  formatSize(size: number): string {
    if (size < 1024) return `${size} B`;
    else if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
    else return `${(size / 1048576).toFixed(2)} MB`;
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }


  handleUpdateDraft() {
    // Logic to update the draft email
    console.log('Draft updated.');

    

    const param = {
      subject: this.selectedRow.subject,
      contentType: 'HTML',
      bodyContent: this.editorData,
      recipientEmails: this.selectedRow.toRecipients.map((email: any) => email.emailAddress.address),
      fromEmail: this.localStorage.getEmail(),
      ccRecipients: this.selectedRow.ccRecipients.map((email: any) => email.emailAddress.address),
      // attachments: this.files,
      client_id: this.localStorage.getClientId(),
      messageId: this.replyId,
      draft_message_id: this.replyId
    }

    this.clearData();

    console.log('Draft email param:', param);
    this.api.updateOutlookDraftEmail(param).subscribe({
      next: (res: any) => {
        console.log('Draft updated successfully:', res);

      },
      error: (err: any) => {
        console.error('Error updating draft:', err);
      }
    });
  }


  closeBottomSheet() {
    if (this.isReply || this.isReplyAll || this.isForward) {
      if (this.files.length > 0) {
        
        this.finalizeClose2();
        
      } else {
        // Directly update draft if there are no files
        this.handleUpdateDraft();
        this.finalizeClose();
      }
    } else {
      // Finalize close if none of the conditions are met
      this.finalizeClose();
    }
  }

  finalizeClose() {
    this.isReply = false;
    this.isReplyAll = false;
    this.isForward = false;
    this.closeCompose.emit();
  }

  finalizeClose2() {
    this.isReply = false;
    this.isReplyAll = false;
    this.isForward = false;
    this.closeCompose2.emit();
  }

  handleEmailAction(actionType: any) {
    if (this.files.length > 0) {
      const attachments = this.files.map((file) => ({
        "@odata.type": "#microsoft.graph.fileAttachment",
        "name": file.file.name,
        "contentType": file.file.type,
        "contentBytes": file.preview?.split(',')[1]
      }));

      const attachmentPayload = {
        fromEmail: this.localStorage.getEmail(),
        draft_id: this.replyId,
        client_id: this.localStorage.getClientId(),
        attachments: attachments
      };

      this.api.addAttachmentInOutlookReply(attachmentPayload).subscribe({
        next: () => this.sendEmail(actionType),
        error: (e) => this.handleError(e, 'Error adding attachments')
      });
    } else {
      this.sendEmail(actionType);
    }
  }

  sendEmail(actionType: any) {
    let recipientEmails: any[] = [];
    if (actionType === 'forward') {
      recipientEmails = this.emails?.length > 0 ? this.emails : [this.emailInput];
    }else if(actionType === 'replyAll'){
      recipientEmails = [this.selectedRow.from.emailAddress.address, ...this.selectedRow.toRecipients.map((email: any) => email.emailAddress.address),
      ...this.selectedRow.ccRecipients.map((email: any) => email.emailAddress.address)
      ];
    }else if(actionType === 'reply'){
      recipientEmails = [this.selectedRow.from.emailAddress.address];
    }

    const emailPayload = {
      fromEmail: this.localStorage.getEmail(),
      draft_message_id: this.replyId,
      contentType: 'HTML',
      client_id: this.localStorage.getClientId(),
      bodyContent: this.editorData,
      recipientEmails: recipientEmails
    };

    this.api.sendOutlookReply(emailPayload).subscribe({
      next: () => this.handleSuccess('Reply sent successfully'),
      error: (e) => this.handleError(e, 'Error sending email')
    });
  }

  handleSuccess(message: any) {
    this.toast.success(message);
    setTimeout(() => {
      this.btnLoading = false;
      this.refresh.emit();
      this.closeCompose.emit();
      this.resetForm();
    }, 1000);
  }

  handleError(error: any, message: any) {
    console.error(error);
    this.toast.error(message);
    this.btnLoading = false;
  }

  resetForm() {
    this.openBCC = false;
    this.openCC = false;
    this.subject = '';
    this.editorData = '';
    this.files = [];
    this.emails = [];
    this.ccEmails = [];
    this.emailInput = '';
    this.ccInput = '';
    this.isReply = false;
    this.isReplyAll = false;
    this.isForward = false;
    this.replyId = null;
    this.closeBottomSheet();
    this.clearData();
  }

  handleReplyMessage() {
    this.handleEmailAction('reply');
  }

  handleReplyAllMessage() {
    this.handleEmailAction('replyAll');
  }

  handleForwardMessage() {
    this.handleEmailAction('forward');
  }


  sendMail() {
    // if (!this.to) {
    //   this.toast.error('Please enter a recipient email address');
    //   return;
    // }'

    if (this.isForward) {

      if (this.emails.length === 0 && !this.emailInput) {
        this.toast.error('Please enter a recipient email address');
        return;
      }

      this.btnLoading = true;

      this.handleForwardMessage();

      return;
    }

    if (this.isReplyAll) {
      if (!this.editorData) {
        this.toast.error('Please enter a message');
        return;
      }

      this.btnLoading = true;

      this.handleReplyAllMessage();

      return;
    }

    if (this.isReply) {
      if (!this.editorData) {
        this.toast.error('Please enter a message');
        return;
      }

      this.btnLoading = true;

      this.handleReplyMessage();

      return;
    }

    if (this.emails.length === 0 && !this.emailInput) {
      this.toast.error('Please enter a recipient email address');
      return;
    }

    if (!this.subject) {
      this.toast.error('Please enter a subject');
      return;
    }

    if (!this.editorData) {
      this.toast.error('Please enter a message');
      return;
    }

    console.log('Sending email...');
    this.btnLoading = true;

    let attachments: any[] = [];

    this.files.forEach((file) => {
      attachments.push({
        "@odata.type": "#microsoft.graph.fileAttachment",
        "name": file.file.name,
        "contentType": file.file.type,
        "contentBytes": file.preview?.split(',')[1]
      });
    });

    const param: any = {
      subject: this.subject,
      contentType: 'HTML',
      bodyContent: this.editorData,
      recipientEmails: this.emails?.length > 0 ? this.emails : [this.emailInput],
      fromEmail: this.localStorage.getEmail(),
      ccRecipients: this.ccEmails?.length > 0 ? this.ccEmails : this.ccInput ? [this.ccInput] : [],
      client_id: this.localStorage.getClientId(),
      draft_message_id: this.currentCreatedDraftId ? this.currentCreatedDraftId : this.selectedRow?.id
    }

    const attachmentPayload = {
      fromEmail: this.localStorage.getEmail(),
      draft_id: this.currentCreatedDraftId ? this.currentCreatedDraftId : this.selectedRow?.id,
      client_id: this.localStorage.getClientId(),
      attachments: attachments
    };
    
    const uploadAttachments$ = attachments.length > 0 
      ? this.api.addAttachmentInOutlookReply(attachmentPayload) 
      : of(null); // Return an observable that emits null if no attachments
    
    uploadAttachments$
      .pipe(
        switchMap(() => 
          this.api.updateOutlookDraftEmail(param) // Update draft email after attachments upload
        ),
        switchMap(() => 
          this.api.sendOutlookDraftEmail(param) // Send email after update
        ),
        catchError((err: any) => {
          console.error('Error occurred:', err);
          this.toast.error('An error occurred while processing your request');
          this.btnLoading = false;
          // Return an empty observable or handle fallback here
          return of(null);
        })
      )
      .subscribe((res: any) => {
        if (res) {
          this.toast.success('Email sent successfully');
          setTimeout(() => {
            this.btnLoading = false;
            this.refresh.emit();
            this.makeIsDraftFalse.emit();
            this.closeCompose.emit();
            this.openBCC = false;
            this.openCC = false;
            this.subject = '';
            this.editorData = '';
            this.files = [];
            this.emails = [];
            this.ccEmails = [];
            this.emailInput = '';
            this.ccInput = '';
          }, 1000);
        }
      });
    



  }

  addEmail(): void {
    if (this.emailInput && this.isValidEmail(this.emailInput)) {
      this.emails.push(this.emailInput);
      this.emailInput = '';  // Clear the input field
    } else {
      // alert('Please enter a valid email address.');
      this.toast.error('Please enter a valid email address.');
    }
  }

  // Remove email from the chip list
  removeEmail(email: string): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  addCc(): void {
    if (this.ccInput && this.isValidEmail(this.ccInput)) {
      this.ccEmails.push(this.ccInput);
      this.ccInput = '';  // Clear the input field
    } else {
      // alert('Please enter a valid CC email address.');
      this.toast.error('Please enter a valid CC email address.');
    }
  }

  // Remove CC email from the chip list
  removeCc(ccEmail: string): void {
    const index = this.ccEmails.indexOf(ccEmail);
    if (index >= 0) {
      this.ccEmails.splice(index, 1);
    }
  }


  // Validate email format using a simple regex (optional)
  isValidEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  clearData() {
    this.subject = '';
    this.editorData = '';
    this.files = [];
    this.emails = [];
    this.ccEmails = [];
    this.emailInput = '';
    this.ccInput = '';
    this.attachments = [];
  }

  fillData(data: any, content: string) {
    this.draft_message_id = data.id;
    this.subject = data.subject;
    this.editorData = content;
    this.emails = data?.toRecipients?.map((email: any) => email.emailAddress.address);
    this.openCC = data.ccRecipients?.length > 0;
    this.ccEmails = data.ccRecipients?.map((email: any) => email.emailAddress.address);
    // this.files = data.attachments;
  }

  handleReply() {
    this.isReply = true;
    this.subject = `Re: ${this.selectedRow.subject}`;
    this.emails = [this.selectedRow.from.emailAddress.address];
    this.openCC = false;
    this.ccEmails = [];

    setTimeout(() => {
      this.scrollToTop();
    }, 100);

    const payload = {
      fromEmail: this.localStorage.getEmail(),
      message_id: this.selectedRow.id,
      client_id: this.localStorage.getClientId()
    }

    this.api.createOutlookReply(payload).subscribe({
      next: (res) => {
        this.replyId = res?.id;

        console.log('Reply ID:', this.replyId);
      },
      error: (e) => {
        console.error(e);
      }
    });

  }

  handleReplyAll() {
    this.isReply = true;
    this.isReplyAll = true;
    this.subject = `Re: ${this.selectedRow.subject}`;
    this.emails = [this.selectedRow.from.emailAddress.address, ...this.selectedRow.toRecipients.map((email: any) => email.emailAddress.address)];

    // remove duplicates
    this.emails = this.emails.filter((email, index, self) => self.indexOf(email) === index);

    this.ccEmails = this.selectedRow.ccRecipients.map((email: any) => email.emailAddress.address);
    this.openCC = this.ccEmails.length > 0;

    setTimeout(() => {
      this.scrollToTop();
    }, 100);


    const payload = {
      fromEmail: this.localStorage.getEmail(),
      message_id: this.selectedRow.id,
      client_id: this.localStorage.getClientId()
    }

    this.api.createOutlookReply(payload).subscribe({
      next: (res) => {
        this.replyId = res?.id;

        console.log('Reply ID:', this.replyId);
      },
      error: (e) => {
        console.error(e);
      }
    });
  }

  handleForward() {
    this.isForward = true;
    this.subject = `Fwd: ${this.selectedRow.subject}`;
    this.emails = [];
    this.openCC = false;
    this.ccEmails = [];

    setTimeout(() => {
      this.scrollToTop();
    }, 100);

    const payload = {
      fromEmail: this.localStorage.getEmail(),
      message_id: this.selectedRow.id,
      client_id: this.localStorage.getClientId()
    }

    this.api.forwardOutlookEmail(payload).subscribe({
      next: (res) => {
        this.replyId = res?.id;
        console.log('Reply ID:', this.replyId);
      },
      error: (e) => {
        console.error(e);
      }
    });


  }

  isImage(file: File): boolean {
    return file?.type?.startsWith('image/');
  }

  isVideo(file: File): boolean {
    return file?.type?.startsWith('video/');
  }

  isPDF(file: File): boolean {
    return file?.type === 'application/pdf';
  }

  scrollToTop(): void {
    console.log("scrolling to top");
    try {
      this.messageContainer.nativeElement.scrollTo({
        top: 0, // Scroll to the top
        behavior: 'smooth' // Enables smooth scrolling
      });
    } catch (err) {
      console.error('Scroll to top failed:', err);
    }
  }

  getImageUrl(contentType: string, contentBytes: string): any {
    if (!contentBytes) return '';
    const base64Url = `data:${contentType};base64,${contentBytes}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64Url);
  }


  openInNewTab(url: string): void {
    // const encodedUrl = encodeURIComponent(url); // Encode if necessary
    // console.log('Opening URL:', encodedUrl);
    // window.open(encodedUrl, '_blank');
  }

  openAttachment(
    content: any, attachment: any
  ) {

    this.selectedAttachment = {
      name: attachment.name,
      contentType: attachment.contentType,
      url: this.getImageUrl(attachment.contentType, attachment.contentBytes)
    };

    this.modalService.open(content, {
      size: 'lg', scrollable: true,
      centered: true,
      backdrop: 'static',
    })
  }

  downloadFile(attachment: { name: string; contentType: string; contentBytes: string }): void {
    const blob = this.base64ToBlob(attachment.contentBytes, attachment.contentType);
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element and trigger download
    const a = document.createElement('a');
    a.href = url;
    a.download = attachment.name; // Set the file name
    a.click();

    // Clean up the URL object
    window.URL.revokeObjectURL(url);
  }

  /**
   * Convert Base64 string to Blob
   */
  private base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64); // Decode Base64
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  onReady(eventData:any) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader:any) {
      console.log(btoa(loader.file));
      return new UploadAdapter(loader);
    };

    eventData.plugins.get('ImageResize').enable({
      handleSize: 50,
      handleStyles: {
        display: 'block',
        'background-color': 'black',
        'border-radius': '50%',
        'border': '1px solid white',
        'box-shadow': '0 0 0 2px black',
        'box-sizing': 'content-box',
        'height': '16px',
        'width': '16px'
      }
    });

    // editorObj.keystrokes.set('Shift+Enter', 'enter');
    // editor.value = editorObj;

    eventData.keystrokes.set('Shift+Enter', 'enter');

  }

  removeAttchment(index: number,
    attachmentId: any
  ) {
    
    this.attachments.splice(index, 1);

    const payload = {
      fromEmail: this.localStorage.getEmail(),
      draft_message_id: this.draft_message_id,
      attachment_id: attachmentId,
      client_id: this.localStorage.getClientId()
    }

    this.api.deleteAttachmentInOutlook(payload).subscribe({
      next: (res) => {
        console.log('Attachment deleted:', res);
      },
      error: (e) => {
        console.error('Error deleting attachment:', e);
      }
    });
  }


}


export class UploadAdapter {
  private loader: any;

  constructor(loader: any) {
    this.loader = loader;
  }

  public async upload(): Promise<any> {
    return this.loader.file
      .then((file: File) => this.readThis(file))
      .then((dataUrl:any) => {
        return { default: dataUrl };
      });
  }

  readThis(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }
}