import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AutoCompleteSearchComponent } from 'src/app/private/modules/components/auto-complete-search/auto-complete-search.component';
import { ContactsService } from 'src/app/private/services/contacts.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent {
  @ViewChild(AutoCompleteSearchComponent) childComponent!: AutoCompleteSearchComponent;
  @Input() formAction: string = 'Create';
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() editData: any;
  @Output() reload: EventEmitter<any> = new EventEmitter();
  btnLoading = false;
  @Input() users: any;
  account: any;

  leadSources: string[] = [
    "Channel Partner",
    "Conference",
    "Field Visit",
    "Google",
    "Instagram",
    "Lead Gen Tools",
    "Live Session",
    "Personal",
    "Referral",
    "LinkedIn",
    "Website",
    "WhatsApp",
    "Tina",
    "Intern",
    "Other"
  ];


  contact = {
    contact_owner: '',
    first_name: '',
    last_name: '',
    fax: '',
    title: '',
    department: '',
    client_id: '',
    center_id: '',
    email: '',
    secondary_email: '',
    skype_id: '',
    description: '',
    phone: '',
    other_phone: '',
    mobile: '',
    address: {
      street: '',
      city: '',
      state: '',
      postal_code: '',
      country: ''
    },
    lead_source: '',
    account_id: '',
    dob: ''
  };



  constructor(
    private toast: ToastrService,
    private api: ContactsService,
    private localStorage: LocalStorageService
  ) { }


  ngOnInit(): void {
    
    if (this.formAction === 'Update') {
      this.contact = this.editData;
      this.account = this.editData?.account;
    }
  }



 

  saveContact(isValid: any) {
    if (isValid) {

      // if((this.childComponent.selectedId === undefined ||
      //   this.childComponent.selectedId === null ||
      //   this.childComponent.selectedId === '') && this.formAction === 'Create') {
      //     this.toast.error('Please select account');
      //     return;
      // }

      console.log('Form submitted:', this.contact);

      this.btnLoading = true;

      const payload = {
        ...this.contact,
        client_id: this.localStorage.getClientId(),
        center_id: this.localStorage.getCenterId(),
        account_id: this.childComponent.selectedId
      }

      if (this.formAction === 'Create') {
        this.api.createContact(payload).subscribe({
          next: (res: any) => {
            this.toast.success('Contact added successfully');
            this.btnLoading = false;
            this.close.emit();
            this.reload.emit();
          },
          error: (e: any) => {
            console.error(e);
            this.toast.error('Error while adding contact');
            this.btnLoading = false;
          }
        });
      } else {
        this.api.updateContact(payload).subscribe({
          next: (res: any) => {
            this.toast.success('Contact updated successfully');
            this.btnLoading = false;
            this.close.emit();
            this.reload.emit();
          },
          error: (e: any) => {
            console.error(e);
            this.toast.error('Error while updating contact');
            this.btnLoading = false;
          }
        });
      }

      // Implement your form submission logic here
    } else {
      console.log('Form is invalid');
    }
  }




  closeModal() {
    this.close.emit();
  }
}