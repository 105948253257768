import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { catchError, debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CompanyService } from 'src/app/private/services/company.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { ContactsService } from 'src/app/private/services/contacts.service';

@Component({
  selector: 'app-auto-complete-contact-search',
  templateUrl: './auto-complete-contact-search.component.html',
  styleUrls: ['./auto-complete-contact-search.component.scss']
})
export class AutoCompleteContactSearchComponent {
  searchControl = new FormControl('');
  @Input() label: string = 'Search Contacts';
  options: any[] = [];
  page = 0;
  isLoading = false;
  selectedId: any = null;
  @Input() account: any;
  @Input() formAction: string = 'Add';
  @Input() selectedName: any = null;
  @Input() account_id: any = null;

  constructor(private http: HttpClient,
    private api: ContactsService,
    private localStorage: LocalStorageService

  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['account_id'] && changes['account_id'].currentValue !== null) {
      this.fetchData('').pipe(
        catchError((error) => {
          console.error('Error fetching data:', error);
          this.isLoading = false; // Stop loading in case of error
          return of({ rows: [] }); // Return an empty result to avoid breaking the app
        })
      ).subscribe(data => {
        this.options = data?.rows;
        this.isLoading = false; // Stop loading after successful fetch
      });
    }
  }

  ngOnInit() {


    if (this.formAction == 'Update') {
      console.log(
        "Hello 123",
        this.account)

        if(this.account.account_id !== null){
      this.searchControl.setValue(this.account.account_name)
      this.selectedId = this.account.account_id
        }
    }

    this.searchControl.valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => {
          this.page = 0;
          this.options = [];
        }),
        switchMap(value => (value ? this.fetchData(value) : of([])))
      )
      .subscribe(data => {
        this.options = data?.rows;
      });


  }

  fetchData(query: any) {
    this.isLoading = true;

    let param: any = {
      "page": this.page,
      "limit": 10,
      where: {
        client_id: this.localStorage.getClientId(),
      },
      "order": {
        "col": "",
        "dir": ""
      }
    };

    if (query) {
      param.filter = {
        first_name: query,
        last_name: query
      };
    }

    // console.log('account_id:', this.account_id);
    // if (this.account_id !== null) {
    //   param.where = {
    //     ...param.where,
    //     'account_id': this.account_id
    //   }
    // }

    return this.api.getAllContacts(param).pipe(
      tap(() => (this.isLoading = false)),
      catchError((error) => {
        this.isLoading = false;
        console.error('Error fetching data:', error); // Optional: Log the error
        return of([]); // Return an empty array or appropriate fallback data
      })
    );
  }


  onScroll(event: Event) {
    const element = event.target as HTMLElement;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight && !this.isLoading) {
      this.page++;
      this.fetchData(this.searchControl.value).subscribe(data => {
        this.options = [...this.options, ...data];
      });
    }
  }

  onSelectionChange(event: any) {
    console.log('Selected:', event);
    this.selectedId = event?.contact_id
  }
}
