import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AutoCompleteSearchComponent } from 'src/app/private/modules/components/auto-complete-search/auto-complete-search.component';
import { CompanyService } from 'src/app/private/services/company.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss']
})
export class CreateCompanyComponent {

  @Input() formAction: string = 'Add';
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() editData: any;
  @Output() reload: EventEmitter<any> = new EventEmitter();
  @Input() users: any;
  @Input() currency: any;
   @ViewChild(AutoCompleteSearchComponent) childComponent!: AutoCompleteSearchComponent;


    constructor(
      private toast: ToastrService,
      private api: CompanyService,
      private localStorage: LocalStorageService,
    ) { }

  btnLoading = false;

  account: any = {
    ParentAccount:null,
    account_owner: null,
    account_site: '',
    account_number: '',
    account_type: '',
    parent_account: '',
    billing_address: {
      street: '',
      city: '',
      state: '',
      postal_code: '',
      country: ''
    },
    shipping_address: {
      street: '',
      city: '',
      state: '',
      postal_code: '',
      country: ''
    },
    description: '',
    ownership: '',
    employees: '',
    sic_code: '',
    gst_no: '',
    tan_no: '',
    annual_revenue: '',
    rating: '',
    fax: '',
    account_name: '',
    industry: '',
    status: '',
    website: '',
    phone_number: '',
    email: ''
  };

  accountOwners = [
    { id: 1, name: 'Owner 1' },
    { id: 2, name: 'Owner 2' },
    { id: 3, name: 'Owner 3' }
  ];

  statuses = ['Active', 'Inactive', 'Pending', 'Approved', 'Rejected', 'Cancelled'];

  // Other, Private, Public, Subsidiary, Partnership, Government, Privately Held, Public Company
  
  ownerships = [
    'Other',
    'Private',
    'Public',
    'Subsidiary',
    'Partnership',
    'Government',
    'Privately Held',
    'Public Company'
  ];

  accountTypes = [
    'Analyst',
    'Competitor',
    'Customer',
    'Distributor',
    'Integrator',
    'Investor',
    'Other',
    'Partner',
    'Press',
    'Prospect',
    'Reseller',
    'Supplier',
    'Vendor'
  ];

  ratings = ['Acquired', 'Active', 'Market Failed', 'Project', 'Cancelled', 'Shut Down'];

  ngOnInit(): void {

    
    if (this.formAction === 'Update') {
      this.account = {
        account_owner: this.editData?.account_owner,
        account_site: this.editData?.account_site,
        account_number: this.editData?.account_number,
        account_type: this.editData?.account_type,
        parent_account: this.editData?.parent_account,
        billing_address: {
          street: this.editData?.billing_address?.street,
          city: this.editData?.billing_address?.city,
          state: this.editData?.billing_address?.state,
          postal_code: this.editData?.billing_address?.postal_code,
          country: this.editData?.billing_address?.country
        },
        shipping_address: {
          street: this.editData?.shipping_address?.street,
          city: this.editData?.shipping_address?.city,
          state: this.editData?.shipping_address?.state,
          postal_code: this.editData?.shipping_address?.postal_code,
          country: this.editData?.shipping_address?.country
        },
        description: this.editData?.description,
        ownership: this.editData?.ownership,
        employees: this.editData?.employees,
        sic_code: this.editData?.sic_code,
        gst_no: this.editData?.gst_no,
        tan_no: this.editData?.tan_no,
        annual_revenue: this.editData?.annual_revenue,
        rating: this.editData?.rating,
        fax: this.editData?.fax,
        account_name: this.editData?.account_name,
        industry: this.editData?.industry,
        status: this.editData?.status,
        website: this.editData?.website,
        phone_number: this.editData?.phone_number,
        email: this.editData?.email,
        ParentAccount: this.editData?.ParentAccount
      };
      
    }

    this.currency = this.localStorage.getClientCurrency();
    console.log('currency', this.currency);
  }






  saveAccount(isValid: any) {
    if (isValid) {
      console.log('Event Data:', this.account);
      
      const params = {
        client_id: this.localStorage.getClientId(),
        center_id: this.localStorage.getCenterId(),
        account_owner: this.account.account_owner,
        account_site: this.account.account_site,
        account_number: this.account.account_number,
        account_type: this.account.account_type,
        parent_account: this.childComponent.selectedId,
        billing_address: {
          street: this.account.billing_address.street,
          city: this.account.billing_address.city,
          state: this.account.billing_address.state,
          postal_code: this.account.billing_address.postal_code,
          country: this.account.billing_address.country
        },
        shipping_address: {
          street: this.account.shipping_address.street,
          city: this.account.shipping_address.city,
          state: this.account.shipping_address.state,
          postal_code: this.account.shipping_address.postal_code,
          country: this.account.shipping_address.country
        },
        description: this.account.description,
        ownership: this.account.ownership,
        employees: this.account.employees,
        sic_code: this.account.sic_code,
        gst_no: this.account.gst_no,
        tan_no: this.account.tan_no,
        annual_revenue: this.account.annual_revenue,
        rating: this.account.rating,
        fax: this.account.fax,
        account_name: this.account.account_name,
        industry: this.account.industry,
        status: this.account.status,
        website: this.account.website,
        phone_number: this.account.phone_number,
        email: this.account.email
      }

      this.btnLoading = true;

      if(this.formAction === 'Update'){
        const parmas = {
          ...params,
          account_id: this.editData.account_id
        }
        this.api.updateCompany(parmas).subscribe((res: any) => {
          console.log('Response:', res);
          this.btnLoading = false;
          this.toast.success('Event updated successfully');
          this.closeModal();
          this.reload.emit();
          
        }, (err: any) => {
          console.error('Error:', err);
          this.btnLoading = false;
          this.toast.error('Failed to update event');
        });

      }else{
        this.api.createComapny(params).subscribe((res: any) => {
          console.log('Response:', res);
          this.btnLoading = false;
          this.toast.success('Event created successfully');
          this.closeModal();
          this.reload.emit();
          
        }, (err: any) => {
          console.error('Error:', err);
          this.btnLoading = false;
          this.toast.error('Failed to create event');
        });
      }

    } else {
      console.error('Form is invalid');
    }
  }

  

  closeModal() {
    this.close.emit();
  }
}
