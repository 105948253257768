import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-add-stage',
  templateUrl: './add-stage.component.html',
  styleUrls: ['./add-stage.component.scss']
})
export class AddStageComponent {

    @Output() close = new EventEmitter();
    @Input() newStageName: string = '';
    @Input() newStageProbability: number | null = null;
    // @Input() stages: any[] = [];
    @Input() btnLoading = false;
    @Output() addStageValue = new EventEmitter();

    constructor(private cdr: ChangeDetectorRef) {}
    closeModal() {
      this.close.emit();
    }


   addStage() {
    this.addStageValue.emit(
      {
        item_id: this.newStageName,
        item_text: this.newStageName,
        probability: this.newStageProbability
      }
    );
    this.closeModal();
   }
  
}
