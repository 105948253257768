import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }

  getAllCompany(data:any): Observable<any> {
    return this.http.post(environment.getAllCompany,data);
  }

  deleteCompany(data:any): Observable<any> {
    return this.http.post(environment.deleteCompany,data);
  }

  createComapny(data:any): Observable<any> {
    return this.http.post(environment.createCompany,data);
  }
  
  updateCompany(data:any): Observable<any> {
    return this.http.post(environment.updateCompany,data);
  }
}
