import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[outsideHover]',
  standalone: true
})
export class OutsideHoverDirective {
  @Output() outsideHover = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:mousemove', ['$event'])
  onHover(event: MouseEvent) {
    const target = event.target as HTMLElement;

    // Check if the hovered element is outside the target element
    if (this.elementRef.nativeElement && !this.elementRef.nativeElement.contains(target)) {
      this.outsideHover.emit();
    }
  }
}
