import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DealService } from 'src/app/private/services/deals.service';

@Component({
  selector: 'app-deals-kanban-dashboard',
  templateUrl: './deals-kanban-dashboard.component.html',
  styleUrls: ['./deals-kanban-dashboard.component.scss']
})
export class DealsKanbanDashboardComponent {

  showStatus: any = [];
  @Input() dataset: any = [];
  @Input() headerColumn: any;
  @Output('updateStatus') updateStatus: EventEmitter<any> = new EventEmitter();
  @Input() currencyCode: any;
  @Input() pipelines: any;
  @Input() users: any;
  @Output() reload: EventEmitter<any> = new EventEmitter();
  @Input() isSaving = false;
  @Input() reloading = false;

  boardFields = [{
    key: 'deal_name',
    name: 'Deal Name'
  },
  {
    key: 'amount',
    name: 'Amount'
  },
  {
    key: 'deal_owner',
    name: 'Deal Owner'
  },
  {
    key: 'lead_source',
    name: 'Lead Source'
  },
  {
    key: 'close_date',
    name: 'Close Date'
  },
  {
    key: 'probability',
    name: 'Probability'
  },
  {
    key: 'deal_stage',
    name: 'Deal Stage'
  },
  {
    key: 'deal_type',
    name: 'Deal Type'
  },
  {
    key: 'deal_priority',
    name: 'Deal Priority'
  },
  ]

  // @Input() pipelines: any;
  @Input() pipelineData: any;
  pipelineValueId: any;


  dealStages: any = [

  ];

  status: any = [];
  formAction: string = 'Add';

  editData: any = null;
  deleteData: any = null;
  btnLoading = false;

  constructor(
    private modalService: NgbModal,
    private api: DealService,
    private toast: ToastrService
  ) {
    console.log(this.dataset)
  }
  // ngOnInit() {
  //   this.status.forEach((element: any) => {
  //     this.showStatus.push(element.value)
  //   });
  // }

  ngOnInit() {
    console.log(this.dataset);

    this.pipelineValueId = this.pipelineData[0].pipeline_id;
    this.dealStages = this.pipelineData[0].stages;

    // Generate random colors for each stage
    this.status = this.dealStages.map((item: any) => {
      const randomColor = `rgb(${this.getRandomInt(0, 128)}, ${this.getRandomInt(0, 128)}, ${this.getRandomInt(0, 128)})`;
      return {
        value: item.stage_name,
        label: item.stage_name,
        color: randomColor
      };
    });

    this.status.forEach((element: any) => {
      this.showStatus.push(element.value); // Match with `id="{{st.value}}"`
    });
    
  }

  // Helper function to generate a random integer between min and max
  private getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }



  public dropGrid(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.status, event.previousIndex, event.currentIndex);
    console.log(event)
  }
  public drop(event: CdkDragDrop<any>, status: any): void {

    if (event.previousContainer === event.container) {

      moveItemInArray(this.dataset, event.previousIndex, event.currentIndex);
    } else {

      console.log(event.previousContainer.data)
      console.log(event.container.data)
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      const movedTicket = event.container.data[event.currentIndex];
      // alert(movedTicket)
      movedTicket[this.headerColumn] = status;  // Set the new status for the moved ticket
      console.log(movedTicket)
      this.updateStatus.emit(movedTicket)
    }
  }
  getStatusWiseTask(status: any) {
    const data = this.dataset.filter((item: any) => { return item[this.headerColumn] == status })
    return data;
  }

  getDealStages(e: any): void {
    const pipelineId = e.target.value;

    let stages = this.pipelineData.filter((item: any) => item.pipeline_id === pipelineId);
    console.log('stages', stages);
    if (stages.length > 0) {
      this.status = stages[0].stages.map((item: any) => {
        const randomColor = `rgb(${this.getRandomInt(0, 128)}, ${this.getRandomInt(0, 128)}, ${this.getRandomInt(0, 128)})`;
        return {
          value: item.stage_name,
          label: item.stage_name,
          color: randomColor
        };
      }
      );
      this.status.forEach((element: any) => {
        this.showStatus.push(element.value); // Match with `id="{{st.value}}"`
      });
    }
  }

  scrollTableTo(direction: any) {
    const el: any = document.querySelector('.content');
    if (direction === 'right') {
      el.scrollBy({
        left: el.offsetWidth,
        behavior: 'smooth',
      });
    } else if (direction === 'left') {
      el.scrollBy({
        left: -el.offsetWidth,
        behavior: 'smooth',
      });
    }
  }


  openEdit(content: any, data: any) {
    this.formAction = 'Update';
    this.editData = data;
    this.modalService.open(content, {
      size: 'lg', scrollable: true,
      centered: true
    })
  }

  openDeleteEvent(content: any, data: any) {
    this.deleteData = data;
    this.modalService.open(content, {
      size: 'md', scrollable: true,
      centered: true
    })
  }

  handleDeleteCompany() {

    const parmas = {
      "deal_id": this.deleteData.deal_id
    }
    this.btnLoading = true;
    this.api.deleteDeal(parmas).subscribe((res: any) => {
      console.log('Response:', res);
      this.toast.success('Company Deleted Successfully');
      this.reloadData();
      this.btnLoading = false;
      this.modalService.dismissAll();
    })
  }

  reloadData() {
    this.reload.emit();
  }



}
