import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PipeLineService } from 'src/app/private/services/pipeline.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';


@Component({
  selector: 'app-create-new-pipeline',
  templateUrl: './create-new-pipeline.component.html',
  styleUrls: ['./create-new-pipeline.component.scss']
})
export class CreateNewPipelineComponent {

  @Output() close = new EventEmitter();
  @Input() formAction = 'Create';
  @Output() reload = new EventEmitter();
  @Input() updateData: any;

  btnLoading = false;

  constructor(
    private localStorage: LocalStorageService,
    private api: PipeLineService,
    // private userApi: UsersService,
    private modalService: NgbModal,
    private toast: ToastrService,
    private cdr: ChangeDetectorRef
  ) { }



  closeModal() {
    this.close.emit();
  }
  stages: any[] = [];
  selectedItems: any[] = [];
  dropdownSettings = {};
  ngOnInit() {
    this.stages = [
      {
        item_id: 'Qualification Needs', item_text: 'Qualification Needs',
        probability: 10
      },
      {
        item_id: 'Analysis Value', item_text: 'Analysis Value',
        probability: 25
      },
      {
        item_id: 'Proposition', item_text: 'Proposition',
        probability: 40
      },
      {
        item_id: 'Identity Decision Makers', item_text: 'Identity Decision Makers',
        probability: 50
      },
      {
        item_id: 'Proposal/Price Quote', item_text: 'Proposal/Price Quote',
        probability: 60
      },
      {
        item_id: 'Negotiation/Review', item_text: 'Negotiation/Review',
        probability: 75
      },
      {
        item_id: 'Closed Won', item_text: 'Closed Won',
        probability: 100
      },
      {
        item_id: 'Closed Lost', item_text: 'Closed Lost',
        probability: 0
      },
      {
        item_id: 'Closed Lost to Competition', item_text: 'Closed Lost to Competition',
        probability: 0
      }
    ];

    this.selectedItems = [

    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    if(this.formAction === 'Update') {
      this.pipelineName = this.updateData.pipeline_name;
      this.selectedItems = this.updateData.stages.map((stage: any) => {
        return {
          item_id: stage.stage_name,
          item_text: stage.stage_name
        };
      });

      let newStages = this.updateData.stages.map((stage: any) => {
        return {
          item_id: stage.stage_name,
          item_text: stage.stage_name,
          probability: stage.probability
        };
      }
      );

      this.stages = this.stages.concat(newStages);

      // remove duplicates

      this.stages = this.stages.filter((stage, index, self) =>
        index === self.findIndex((t) => (
          t.item_id === stage.item_id
        ))
      );

      console.log('Updated Stages:', this.stages);


    }
  
  }
  

  pipelineName: string = '';

  selectedStages: Array<any> = [];


  // Variables for new stage modal
  newStageName: string = '';
  newStageProbability: number | null = null;

  savePipeline(isValid: any): void {
    if (isValid) {
      console.log("stages",this.stages)
      console.log('Pipeline Name:', this.pipelineName);
      console.log('Selected Stages:', this.selectedItems);

      this.btnLoading = true;

      const updatedStages = this.selectedItems.map((stage: any) => {
        return {
          stage_name: stage.item_id,
          probability: this.stages.find((s: any) => s.item_id === stage.item_id).probability
        };
      });
      
      const payload:any = {
        pipeline_name: this.pipelineName,
        stage_status: 'Active',
        client_id: this.localStorage.getClientId(),
        stages: updatedStages,
        set_default: false
      };

     
      if(this.formAction === 'Update'){

        payload['pipeline_id'] = this.updateData.pipeline_id;

        this.api.updatePipeLine(payload).subscribe({
          next: (res: any) => {
            console.log('Update Pipeline Response:', res);
            this.toast.success('Pipeline updated successfully');
            this.closeModal();
            this.reload.emit();
          },
          error: (err: any) => {
            console.error('Update Pipeline Error:', err);
            this.toast.error('Failed to update pipeline');
            this.btnLoading = false;
          }
        });

        
      }else{
        this.api.createPipeLine(payload).subscribe({
          next: (res: any) => {
            console.log('Create Pipeline Response:', res);
            this.toast.success('Pipeline created successfully');
            this.closeModal();
            this.reload.emit();
          },
          error: (err: any) => {
            console.error('Create Pipeline Error:', err);
            this.toast.error('Failed to create pipeline');
            this.btnLoading = false;
          }
        });
      }

    }
  }

  openAddStageModal(content: any): void {

    this.modalService.open(content, { size: 'md', scrollable: true, centered: true, backdrop: 'static', keyboard: false });

  }

  onItemSelect(item: any) {
    console.log("item",item);
  }
  onSelectAll(items: any) {
    // this.selectedItems = items;
    console.log('Selected Items:', this.selectedItems);
  }

  addStage(event: any): void {
    console.log('Event:', event);
    this.newStageName = event.item_id;
    this.newStageProbability = event.probability;

    console.log('New stage added:', this.newStageName, this.newStageProbability);
    if (this.newStageName && this.newStageProbability !== null && this.newStageProbability !== null) {
      const newStage = {
        item_id: `${this.newStageName}`,
        item_text: `${this.newStageName}`,
        probability: this.newStageProbability
      };

      // Add the new stage and assign a new array reference
      this.stages = [...this.stages, newStage];

  

      console.log('New stage added:', this.stages);

      // Clear the input fields
      this.newStageName = '';
      this.newStageProbability = null;

      // Close the modal
    } else {
      this.toast.error('Please enter a valid stage name and probability');
    }
  }


}
