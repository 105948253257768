import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EmailHubService } from 'src/app/private/services/email-hub.service';
import { LeadService } from 'src/app/private/services/lead.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
@Component({
  selector: 'app-lead-email',
  templateUrl: './lead-email.component.html',
  styleUrls: ['./lead-email.component.scss']
})
export class LeadEmailComponent {
  files: any[] = [];
  public Editor = ClassicEditor as unknown as any;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() communicationData: any;
  btnLoading = false;
  templateSelection: string = 'custom'; // Default to custom template
  editorData: string = ''; // CKEditor data
  selectedTemplate: string | null = null;
  templates: any[] = [];
  emailSubject: string = '';
  isFetchingTemplates = false;
  sanitizedData: SafeHtml = '';
  btnLoading2 = false;
  notes: string = '';
  btnLoading3 = false;
  emails: string[] = [];
  templateDataToSend: any = {};
  currentSingature: any = '';

  @ViewChild('Content1') content1: any;


  userData: any = {
    contact_name: 'Gaurav',
    contact_phone: '123-456-7890',
    company_name: 'YourCompany'
  };

  constructor(
    private toast: ToastrService,
    private api: LeadService,
    private api2: EmailHubService,
    private localStorage: LocalStorageService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) { }


  ngOnInit() {
    console.log('Communication Data:', this.communicationData);
    this.handleGetTemplates();

    let signature = this.localStorage.getSignatures();
    signature.forEach((element: any) => {
      if (element.isActive === true || element.isActive === 'true') {
        this.currentSingature = element.signature;
        this.editorData = `<br><br><br><br>${this.currentSingature}`
      }
    });

    

  }

  handleGetTemplates() {
    // Get templates from API
    const data = {
      "client_id": this.localStorage.getClientId()
    }

    this.api.getAllEmailTemplates(data).subscribe((res: any) => {
      this.templates = res;
    }
    );
  }

  onEditorChange() {
    // Handle CKEditor data change
  }

  onTemplateChange(event: any) {
    console.log('Selected Template:', event.target.value);
  }

  processAction(content: any) {

    if (this.emailSubject === '') {
      this.toast.error('Please enter email subject');
      return;
    }

    if (this.templateSelection === 'custom') {
      console.log('Custom Email Content:', this.editorData);
      if (this.editorData === '') {
        this.toast.error('Please enter email content');
        return;
      }
    } else {
      if (this.selectedTemplate === null) {
        this.toast.error('Please select a template');
        return;
      }
    }

    // this.btnLoading = true;
    // // Simulate a process or API call
    // setTimeout(() => {
    //   this.btnLoading = false;
    //   console.log('Action processed');
    // }, 2000);

    if (this.templateSelection !== 'custom') {
      console.log('Selected Template:', this.selectedTemplate);
      this.handleGetEmailTemplateById(this.selectedTemplate);
    }


    this.modalService
      .open(content, {
        size: 'md', scrollable: true, centered: true,
        backdrop: 'static', keyboard: false
      })
  }

  handleGetEmailTemplateById(id: any) {
    this.isFetchingTemplates = true;
    const payload = {
      "client_id": this.localStorage.getClientId(),
      "template_id": id
    }
    this.api.getEmailTemplateById(payload).subscribe(
      (res: any) => {
        let updatedBody = res.template_body
        for (const key in this.communicationData) {
          if (this.communicationData.hasOwnProperty(key)) {
            const value = this.communicationData[key];
            // Replace the placeholder (e.g., {{contact_name}}) with the corresponding value
            const placeholder = `{{${key}}}`;
            const regex = new RegExp(placeholder, 'g');
            updatedBody = updatedBody.replace(regex, value);
          }
        }
        this.templateDataToSend = updatedBody;
        this.sanitizedData = this.sanitizer.bypassSecurityTrustHtml(updatedBody);

        console.log('sanitizedData Data 123:', this.sanitizedData);

        this.isFetchingTemplates = false
      },
      (error: any) => {
        this.isFetchingTemplates = false
        console.error('Error creating campaign', error);

      }

    );
  }

  Submit() {
    this.btnLoading2 = true;

    if (this.templateSelection === 'custom') {

      // const formData = new FormData();

      // // console.log('Files:', this.files);
      // const attachmentsArray: any[] = [];

      // this.files.forEach((fileObj, index) => {
      //   // console.log('File:', fileObj);
      //   // formData.append(`attachments[${index}][filename]`, fileObj.file.name);
      //   // formData.append(`attachments[${index}][content]`, fileObj.preview);
      //   // formData.append(
      //   //   `attachments[${index}][contentType]`,
      //   //   fileObj.file.type || 'application/octet-stream'
      //   // );
      //   attachmentsArray.push({
      //     "filename": fileObj.file.name,
      //     "content": fileObj.preview,
      //     // "contentType": fileObj.file.type || 'application/octet-stream',
      //     "encoding": "base64"
      //   });
      // });



      // const payload = {
      //   "client_id": this.localStorage.getClientId(),
      //   "subject": this.emailSubject,
      //   "text": "",
      //   "html": this.editorData,
      //   "to": this.communicationData?.contact_email,
      //   "attachments": attachmentsArray
      // }

      // this.files.forEach((fileObj, index) => {
      //   console.log('File:', fileObj);
      //   formData.append(`attachments[${index}][filename]`, fileObj.file.name);
      //   formData.append(`attachments[${index}][content]`, fileObj.preview); // Use file object directly
      //   formData.append(
      //     `attachments[${index}][contentType]`,
      //     fileObj.file.type || 'application/octet-stream'
      //   );
      // });

      // // Add other fields to FormData
      // formData.append('client_id', this.localStorage.getClientId());
      // formData.append('subject', this.emailSubject);
      // formData.append('text', '');
      // formData.append('html', this.editorData);
      // formData.append('to', this.communicationData?.contact_email);

      // this.api.sendEmail(payload).subscribe(
      //   {
      //     next: (res: any) => {
      //       this.toast.success('Email sent successfully');
      //       this.btnLoading2 = false;
      //       // this.closeModal2();
      //       this.modalService
      //         .open(this.content1, {
      //           size: 'lg', scrollable: true, centered: true,
      //           backdrop: 'static', keyboard: false
      //         })
      //     },
      //     error: (error: any) => {
      //       this.toast.error('Error sending email');
      //       this.btnLoading2 = false;
      //       console.error('Error sending email', error);
      //     }
      //   }
      // );

      let attachments: any[] = [];

      this.files.forEach((file) => {
        attachments.push({
          "@odata.type": "#microsoft.graph.fileAttachment",
          "name": file.file.name,
          "contentType": file.file.type,
          "contentBytes": file.preview
        });
      });

      const param = {
        subject: this.emailSubject,
        contentType: 'HTML',
        bodyContent: this.editorData,
        recipientEmails: this.emails?.length > 0 ? this.emails : [this.communicationData?.contact_email],
        fromEmail: this.localStorage.getEmail(),
        ccRecipients: [],
        attachments: attachments,
        client_id: this.localStorage.getClientId()
      }

      this.api2.sendEmailUsingOutlook(param).subscribe({
        next: (res) => {


          this.toast.success('Email sent successfully');

          this.btnLoading2 = false;
          // this.closeModal2();
          this.modalService
            .open(this.content1, {
              size: 'lg', scrollable: true, centered: true,
              backdrop: 'static', keyboard: false
            })

        },
        error: (e) => {
          console.log(e);
          this.toast.error('Error sending email');
          this.btnLoading2 = false;
        }
      })






    } else {


      const param = {
        subject: this.emailSubject,
        contentType: 'HTML',
        bodyContent: this.templateDataToSend,
        recipientEmails: [this.communicationData?.contact_email],
        fromEmail: this.localStorage.getEmail(),
        ccRecipients: [],
        attachments: [],
        client_id: this.localStorage.getClientId()
      }

      this.api2.sendEmailUsingOutlook(param).subscribe({
        next: (res) => {


          this.toast.success('Email sent successfully');

          this.btnLoading2 = false;
          // this.closeModal2();
          this.modalService
            .open(this.content1, {
              size: 'lg', scrollable: true, centered: true,
              backdrop: 'static', keyboard: false
            })

        },
        error: (e) => {
          console.log(e);
          this.toast.error('Error sending email');
          this.btnLoading2 = false;
        }
      })




    }

  }

  closeModal() {
    this.close.emit();
  }

  closeModal2() {
    this.modalService.dismissAll();
  }

  onFileSelected(event: any) {
    const selectedFiles: FileList = event.target.files;
    console.log('Selected Files:', selectedFiles);
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const reader = new FileReader();
      console.log('File:', file);
      reader.onload = (e: any) => {
        this.files.push({ file, preview: e.target.result });
        console.log('Files:', this.files);
      };

      reader.readAsDataURL(file);  // Generate preview
    }
  }

  // Remove a File from the List
  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  // Helper: Check if File is an Image
  isImage(file: File): boolean {
    return file.type.startsWith('image/');
  }

  // Helper: Check if File is a Video
  isVideo(file: File): boolean {
    return file.type.startsWith('video/');
  }

  // Helper: Format File Size for Display
  formatSize(size: number): string {
    if (size < 1024) return `${size} B`;
    else if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
    else return `${(size / 1048576).toFixed(2)} MB`;
  }

  saveNotes() {
    try {
      this.btnLoading3 = true;

      const payload = {

        "lead_id": this.communicationData?.lead_id,
        "client_id": this.communicationData?.client_id,
        "communication_type": "Email",
        "note": this.notes,
        "date_of_communication": new Date().toISOString().split('T')[0],
        "email_extra_details": {
          "subject": this.emailSubject,
          "template_id": this.selectedTemplate,
          "template_name": this.selectedTemplate,
          "attachments": this.files,
          "email_content": this.editorData,
          "to": this.communicationData?.contact_email
        },
        "status": "Sent"

      }

      this.api.CreateCommunicationHisLog(payload).subscribe({
        next: (res: any) => {
          this.toast.success('Notes saved successfully');
          this.modalService.dismissAll();
          this.btnLoading3 = false;
        },
        error: (err: any) => {
          this.modalService.dismissAll();
          this.btnLoading3 = false;
        }
      });



    } catch (e) {
      this.btnLoading3 = false;
      this.toast.error('Error saving notes');
      console.error(e);
    }
  }


}
