import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EmailHubService } from 'src/app/private/services/email-hub.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-mail-left-actions',
  templateUrl: './mail-left-actions.component.html',
  styleUrls: ['./mail-left-actions.component.scss']
})
export class MailLeftActionsComponent {
  isSmallScreen = false;

  @Input() activeAction: string = 'inbox';
  @Output() actionChange = new EventEmitter<string>();
  @Output() refresh = new EventEmitter<void>();
  @Output() isCompose = new EventEmitter<void>();
  @Input() updateDraft = false;



  actions = [
    {
      label: 'Inbox',
      icon: 'inbox',
      action: 'inbox'
    },
    {
      label: 'Sent',
      icon: 'paper-plane',
      action: 'sent'
    },
    {
      label: 'Drafts',
      icon: 'file-alt',
      action: 'drafts'
    },
    {
      label: 'Junk',
      icon: 'archive',
      action: 'trash'
    },
    {
      label:'Deleted',
      icon:'trash',
      action:'deleted'
    }
  ]

  constructor(
    private breakpointObserver: BreakpointObserver,
    private bottomSheet: MatBottomSheet,
    private toast: ToastrService,
  ) {}

  ngOnInit() {
    this.breakpointObserver.observe(['(max-width: 780px)']).subscribe((result) => {
      this.isSmallScreen = result.matches;
    });
  }

  setActiveAction(action: string) {
    this.actionChange.emit(action);
  }

  reloadData() {
    this.refresh.emit();
  }

  openBottomSheet(): void {
    this.isCompose.emit();
  }
}
@Component({
  selector: 'bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./mail-left-actions.component.scss'],
  imports: [CommonModule,
    CKEditorModule,
    FormsModule
  ],
  // standalone: true
  standalone: true
})
export class BottomSheetActionsComponent {

  openBCC = false;
  openCC = false;
  public Editor = ClassicEditor as unknown as any;
  editorData: string = ''; // CKEditor data
  files: any[] = [];
  to: string = '';
  cc: string = '';
  bcc: string = '';
  subject: string = '';
  btnLoading = false;

  @Output() refresh = new EventEmitter<void>();



  constructor(
    private bottomSheetRef: MatBottomSheetRef<BottomSheetActionsComponent>,
    private toast: ToastrService,
    private api: EmailHubService,
    private localStorage: LocalStorageService
  ) {}

  selectAction(action: string): void {
    this.bottomSheetRef.dismiss(action);
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  handleOpenBCC() {
    this.openBCC = !this.openBCC;
  }

  handleOpenCC() {
    this.openCC = !this.openCC;
  }

  onFileSelected(event: any) {
    const selectedFiles: FileList = event.target.files;
    console.log('Selected Files:', selectedFiles);
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const reader = new FileReader();
      console.log('File:', file);
      reader.onload = (e: any) => {
        this.files.push({ file, preview: e.target.result });
        console.log('Files:', this.files);
      };

      reader.readAsDataURL(file);  // Generate preview
    }
  }

  formatSize(size: number): string {
    if (size < 1024) return `${size} B`;
    else if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
    else return `${(size / 1048576).toFixed(2)} MB`;
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }
  

  closeBottomSheet() {
    this.bottomSheetRef.dismiss();
  }

  sendMail() {
    if (!this.to) {
      this.toast.error('Please enter a recipient email address');
      return;
    }

    if (!this.subject) {
      this.toast.error('Please enter a subject');
      return;
    }

    if (!this.editorData) {
      this.toast.error('Please enter a message');
      return;
    }

    console.log('Sending email...');
    this.btnLoading = true;

    let attachments:any[] = [];

    this.files.forEach((file) => {
      attachments.push({
        "@odata.type": "#microsoft.graph.fileAttachment",
        "name": file.file.name,
        "contentType": file.file.type,
        "contentBytes": file.preview
      });
    });
 
    const param = {
      subject: this.subject,
      contentType: 'HTML',
      bodyContent: this.editorData,
      recipientEmail: this.to,
      fromEmail: this.localStorage.getEmail(),
      attachments: attachments
    }

    this.api.sendEmailUsingOutlook(param).subscribe({
      next: (res) => {
        
        this.refresh.emit();
        
        setTimeout(() => {
          this.btnLoading = false;
        this.closeBottomSheet();
        this.toast.success('Email sent successfully');
        }, 1500);
        
      },
      error: (e) => {
        console.error(e);
        this.toast.error('Error sending email');
        this.btnLoading = false;
      }
    })
  

    
  }

}
